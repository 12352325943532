import Comp, { ScrollDot } from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '滑屏图片',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [ScrollDot],
    type: 'normal-scroll',
    id: 'id11', // 测试
    props: {
        scrollList: [
            {
                imageUrl: '//img14.360buyimg.com/zx/jfs/t1/126971/17/17224/388817/5f9fcdefE488b3e92/041ef907bbe86c7b.jpg',
                hotArea: [],
            },
            {
                imageUrl: '//img20.360buyimg.com/zx/jfs/t1/141421/29/12865/455498/5f9fcdefE7230be9e/10ab0674b4f69bb8.jpg',
                hotArea: [],
            }
        ],
        between: 0,
        scrollItemWidth: 375,
        scrollItemHeight: 370,
        scrollWrapWidth: 375,
        subProps: [
            {
                showNumber: 0,
                defaultWidth: 30,
                activeWidth: 50,
                between: 5,
                radius: 4,
                defaultColor: '#000000',
                activeColor: '#ffffff',
            }
        ],
    },
    extension: {
        scrollWidthDuration: [0, 1125],
        speedDuration: [1, 7],
        struct: {
            imageUrl: '',
            hotArea: [],
        }
    },
    rectOptions: {
        width: 375,
        height: 370,
        left: 0,
        top: 30,
        rotate: 0,
        subRectOptions: [
            {
                width: 200,
                height: 10,
                left: 120,
                top: 460,
                rotate: 0,
            },
        ]
    },
    drawOptions: {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: false,
        renderDirections: ['e'],
        subOptions: [
            {
                resize: false,
                drag: true,
                rotate: false,
                keepRatio: false,
                // renderDirections: ['n', 's']
            }
        ],
    },
}

export default Plugin
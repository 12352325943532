import React, { useMemo } from 'react'

export default function Slide({ rectOptions, props }) {
    const { left, top } = rectOptions
    const { imgWidth, imgHeight, slideWidth, banners, shrinkScale, opacity } = props
    const renderBanners = useMemo(() => {
        const result = []
        for (let i = 0; i < 5; i++) {
            result.push(...banners)
        }
        return result
    }, [banners])
    return (
        <div
            style={{
                position: 'absolute',
                width: slideWidth,
                height: imgHeight,
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'flex-start',
                transform: `translate(${left}px, ${top}px)`,
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    display: 'flex',
                    left: -banners.length * 2 * imgWidth + slideWidth /  2 - imgWidth / 2
                }}
            >
            {
                renderBanners.map((item, index) => {
                    return (
                        <img
                            key={index}
                            style={{
                                transform: `scale(${banners.length * 2 === index ? 1 : shrinkScale})`,
                                opacity: banners.length * 2 === index ? 1 : opacity,
                                flexShrink: 0,
                                width: imgWidth,
                                height: imgHeight,
                            }}
                            src={item.imageUrl}
                            alt=""
                        />
                    )
                })
            }
            </div>
            
        </div>
    )
}

export function Dot({ rectOptions, props }) {
    const { height, top, left } = rectOptions.subRectOptions[0]
    const {
        defaultWidth,
        activeWidth,
        between,
        radius,
        defaultColor,
        activeColor
    } = props.subProps[0]
    
    return (
        <div
            className="dot-comp-wrap items-center"
            style={{
                position: 'absolute',
                height,
                transform: `translate(${left}px, ${top}px)`,
            }}
        >
            {
                props.banners.map((v, i) => {
                    return (
                        <div
                            key={'dot_normal-banner' + i}
                            style={{
                                width: i === 0 ? activeWidth : defaultWidth,
                                height: '100%',
                                marginLeft: i === 0 ? 0 : between,
                                borderRadius: radius,
                                backgroundColor: i === 0 ? activeColor : defaultColor
                            }}
                        >

                        </div>
                    )
                })
            }
        </div>
    )
}

// '0186091c134543fbb36384a8145594f6app_keyEA2756657087E8F37AE85E5C2B75A775methodjingdong.miniapp.mixUserInfotimestamp2023-11-17 11:14:590186091c134543fbb36384a8145594f6'
import React, { Fragment, useState } from 'react'
import { Space } from 'antd'
import '@/plugins/desc.less'
import { ORIGIN_DATA_TYPE } from '@/consts/plugin'
import schema from './schema.json'
import { GroupDesc, SpaceDesc } from '../_component/descComponent'
import { getConfigDataType } from '../schemaUtils'
import {
    Input,
    Slider,
    Select,
    ConfigTitle,
} from '@/components'
import { InputNumber3x, InputWithImg } from '@/HOC'

const { config_ui } = schema
export default function Desc(descProps) {
    const { props, rectOptions, extension } = descProps.currentPlugin
    return (
        <div
            className="desc-wrap"
        >
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="翻转换一换"
                detail="共9组，每组支持2张图片切换"
            />
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={16}
                >
                    {
                        config_ui.map((config, index) => {
                            let renderData = {}
                            let renderMethod = () => {}
                            switch(getConfigDataType(schema.data, config)) {
                                case ORIGIN_DATA_TYPE.PROPERTIES:
                                    renderData = props
                                    renderMethod = descProps.updateCurrentPluginProps
                                    break
                                case ORIGIN_DATA_TYPE.RECT:
                                    renderData = rectOptions
                                    renderMethod = descProps.changeCurrentPluginRect
                                    break
                            }
                            return <Fragment key={index}>
                                {
                                    config.type === 'Group' && GroupDesc({
                                        props,
                                        rectOptions,
                                        config,
                                        schema,
                                        ...descProps
                                    })
                                }
                                {
                                    config.type === 'Space' && <SpaceDesc
                                        schema={schema}
                                        props={props}
                                        rectOptions={rectOptions}
                                        config={config}
                                        {...props}
                                    />
                                }
                                {
                                    config.type === 'Slider' && <Slider
                                        {...config.props}
                                        defaultValue={renderData[config.origin_data.key]}
                                        min={extension[`${config.origin_data.key}Range`][0]}
                                        max={extension[`${config.origin_data.key}Range`][1]}
                                        onChange={(v) => {
                                            renderMethod({
                                                [`${config.origin_data.key}`]: v
                                            })
                                        }}
                                    />
                                }
                            </Fragment>
                        })
                    }
                </Space>
            </div>
        </div>
    )
}
import Comp from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '锚点组件',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [],
    type: 'nav-comp',
    id: 'id5', // 测试
    props: {
        navList: [{
            defaultUrl: '//img11.360buyimg.com/zx/jfs/t1/122795/39/17242/9533/5fa1383cE38c051cf/f966c49d64d6d50e.jpg',
            activeUrl: '//img14.360buyimg.com/zx/jfs/t1/145033/19/13112/8111/5fa1383cE6a45d35a/025f5f50c7423ea6.jpg',
            },
            {
            defaultUrl: '//img13.360buyimg.com/zx/jfs/t1/141095/17/13198/9820/5fa1383cE816dd090/7c751ab3d776c7d5.jpg',
            activeUrl: '//img11.360buyimg.com/zx/jfs/t1/131987/36/14134/8312/5fa1383cEcbf73302/e3bab0a8618a0fe4.jpg',
            },
        ],
        navWidth: 94,
        navHeight: 30,
        between: 0,
        direction: 1, // 0 垂直 1 水平
    },
    extension: {
        struct: {
            defaultUrl: '',
            activeUrl: '',
        }
    },
    rectOptions: {
        width: 375,
        height: 375,
        left: 0,
        top: 143,
        rotate: 0,
        subRectOptions: []
    },
    drawOptions: {
        resize: false,
        drag: true,
        rotate: false,
        keepRatio: false,
        subOptions: []
    },
}

export default Plugin
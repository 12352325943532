import React, { useRef, useCallback, useEffect } from 'react'

const flyImageId = 'tgb_fly-image_' + Date.now()

export default function Preview({
    baseConfig,
    rectOptions,
    props,
    zIndex = 0,
}) {
    const { subRectOptions } = rectOptions
    const { subProps } = props
    const hybridList = subRectOptions.map((item, index) => {
        return Object.assign(item, subProps[index])
    })

    useEffect(() => {
    }, [])

    const initAnimateStyle = (hybridItem) => {
        let style = {}
        switch(hybridItem.flyDirection) {
            case '左':
                style = {
                    transform: `translateX(-${hybridItem.flyDistance}px)`,
                }
                break
            case '右':
                style = {
                    transform: `translateX(${hybridItem.flyDistance}px)`
                }
                break
            case '上':
                style={
                    transform: `translateY(-${hybridItem.flyDistance}px)`
                }
                break
            case '下':
                style={
                    transform: `translateY(${hybridItem.flyDistance}px)`
                }
                break
        }
        style.transition = `${hybridItem.flyTime}s`
        return style
    }

    return (
        <>
            {
                hybridList.map((item, index) => {
                    <div
                        id={flyImageId + '-' + index}
                        style={Object.assign({
                            width: item.width,
                            height: item.height,
                            position: 'absolute',
                            left: item.left,
                            top: item.top,
                            zIndex,
                        }, initAnimateStyle(item))}
                    >

                    </div>
                })
            }
        </>
    )
}
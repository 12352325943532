import { useState, createContext, useRef } from 'react'

export const HotAreaConfigContext = createContext({})

const HotAreaConfig = ({ children }) => {
    const [contentSize, setContentSize] = useState({
        width: 375,
        height: 667,
    })
    const [content, setContent] = useState({})
    const [defaultValue, setDefaultValue] = useState([])
    const contentFit = useRef('contain')
    const handleSaveCallback = useRef(() => {})
    return (
        <HotAreaConfigContext.Provider
            value={{
                contentSize,
                content,
                defaultValue,
                setDefaultValue,
                setContent,
                setContentSize,
                setSaveCb: (cb) => {
                    handleSaveCallback.current = cb
                },
                contentFit: contentFit.current,
                setContentFit: (fit) => {
                    contentFit.current = fit
                },
                onSave: (data) => {
                    handleSaveCallback.current(data)
                    handleSaveCallback.current = () => {}
                },
            }}
        >
            {children}
        </HotAreaConfigContext.Provider>
    )
}

export default HotAreaConfig
import React, { useState, useEffect } from 'react'
import { Radio } from 'antd'
import './index.less'

export default function RadioCustom({
    style = {
        width: 320,
    },
    title = '单选',
    radioList = ['A', 'B', 'C', 'D'],
    defaultValue = 0,
    onChange = () => {},
    radioType = 'normal', // button | normal
}) {
    const [value, setValue] = useState(defaultValue)
    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])
    return (
        <div className="radio-custom-wrap flex-col items-start" style={style}>
            <div className="title">
                {title}
            </div>
            <Radio.Group
                onChange={(e) => {
                    const result = e.target.value
                    setValue(result)
                    onChange(result)
                }}
                value={value}
            >
                {
                    radioList.map((item, index) => {
                        return (
                            radioType === 'normal' ?
                            (
                                <Radio
                                    key={'radio' + title + index}
                                    value={index}
                                >{item}</Radio>
                            ) :
                            (
                                <Radio.Button
                                    key={'radio' + title + index}
                                    value={index}
                                >
                                    {item}
                                </Radio.Button>
                            )
                            
                        )
                    })
                }
            </Radio.Group>
        </div>
    )
}
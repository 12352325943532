import React, { useState } from 'react'
import { Space } from 'antd'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import '@/plugins/desc.less'
import {
    Input, // 文本输入框 ---- 文本填写
    ConfigTitle, // 标题，可用作大标题和小标题
    InputNumber, // 数字输入框 ---- 数字填写（涉及尺寸的时候）
    Slider, // 滑动条 ---- 滑动游尺
    Radio, // 单选选择器 ---- 单选框
    Select, // select Tab 选择器 ---- 涉及到轮播等添加帧数的组件时候用到（具体使用参考HotBanner组件)
    BaseButton, // 按钮 ---- 按钮 具体用法参考AccordionBanner 第79-98
    ColorPicker, // 颜色选择器 ---- 颜色选择
    TextArea, // 多行文本输入框
} from '@/components'
import {
    HotArea, // 热区 ---- 热区（具体使用参考HotBanner组件)
    InputNumber3x, // 3倍数字输入框 ---- 数字填写（涉及尺寸的时候）
    Slider3x, // 3倍数字滑动条 ---- 滑动游尺（涉及尺寸的时候）
    InputWithImg, // 输入框带图片或视频 ---- 媒体资源填写
} from '@/HOC'

export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
    deleteInsertPlugin, // 删除功能 具体用法参考AccordionBanner 第79-98行
}) {
    const { props, rectOptions, extension } = currentPlugin
    const { width, height, left, top } = rectOptions
    const { imageUrl, name } = props
    return (
        <div
            className="desc-wrap"
        >
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="待修改"
                detail="待修改"
            />
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="小标题"
                        detail="小标题描述"
                        style={{
                            height: 'auto'
                        }}
                        titleSize={14}
                        detailSize={12}
                    />
                    <InputWithImg
                        labelContent="图片/动图链接"
                        value={imageUrl}
                        defaultValue={imageUrl}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                imageUrl: e,
                            })
                        }}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    width: v,
                                })
                            }}
                            defaultValue={width}
                        />
                        <InputNumber3x
                            labelContent="高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    height: v,
                                })
                            }}
                            defaultValue={height}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    top: v,
                                })
                            }}
                            defaultValue={top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    left: v,
                                })
                            }}
                            defaultValue={left}
                        />
                    </Space>
                </Space>
            </div>
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="小标题2"
                        detail="小标题2描述"
                        style={{
                            height: 'auto'
                        }}
                        titleSize={14}
                        detailSize={12}
                    />
                    <InputWithImg
                        labelContent="图片/动图链接"
                        value={imageUrl}
                        defaultValue={imageUrl}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                imageUrl: e,
                            })
                        }}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    width: v,
                                })
                            }}
                            defaultValue={width}
                        />
                        <InputNumber3x
                            labelContent="高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    height: v,
                                })
                            }}
                            defaultValue={height}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    top: v,
                                })
                            }}
                            defaultValue={top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    left: v,
                                })
                            }}
                            defaultValue={left}
                        />
                    </Space>
                </Space>
            </div>
        </div>
    )
}
import Comp, { ScrollDotOrNormal, MutedBtn, Dot } from './comp'
import Preview from './preview'
import Desc from './desc'

const Plugin = {
    name: '视频轮播',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [ScrollDotOrNormal, MutedBtn],
    type: 'banner',
    id: 'id3', // 测试
    props: {
        banners: [{
                poster: '',
                mask: '',
                mediaUrl: '//img10.360buyimg.com/zx/jfs/t1/144624/26/12304/588320/5f97abbaEa4e6c59e/4461c6399fea9196.jpg',
                jumpUrl: '',
                hotArea: [],
            },
            {
                poster: '',
                mask: '',
                mediaUrl: 'https://jvod.300hu.com/vod/product/e6c1cc3b-c948-4e6c-881c-11cd7add4e52/c01fed09c99a49f2ad5b5d7e4deeced0.mp4?source=2&h265=h265/18799/29eadccdd2204c8b92fc73fec387f7ae.mp4',
                jumpUrl: '',
                hotArea: [],
            }
        ],
        subProps: [{
            type: 0, // 0 静态进度点 1动态进度点
            showNumber: 0,
            defaultWidth: 30,
            activeWidth: 50,
            between: 5,
            radius: 4,
            defaultColor: '#000000',
            activeColor: '#ffffff',
        }, {
            muted: true,
            mutedImgUrl: '//img10.360buyimg.com/imgzone/jfs/t1/226053/22/4717/607/65642a12Fefed02ad/08233b60ca616488.png',
            unmutedImgUrl: '//img10.360buyimg.com/imgzone/jfs/t1/236552/28/4624/601/65642a12Fa80102c4/25568bd4535620f0.png',
        }],
        videoInterval: 8,
        imageInterval: 4,
    },
    extension: {
        videoIntervalDuration: [1, 10],
        imageIntervalDuration: [1, 10],
        struct: {
            mediaUrl: '',
            jumpUrl: '',
            videoInterval: 1,
            imageInterval: 1,
            hotArea: [],
        }
    },
    rectOptions: {
        width: 375,
        height: 475,
        left: 0,
        top: 0,
        rotate: 0,
        subRectOptions: [
            {
                width: 200,
                height: 10,
                left: 120,
                top: 460,
                rotate: 0,
            },
            {
                left: 10,
                top: 10,
                width: 20,
                height: 20,
                rotate: 0,
            }
        ]
    },
    drawOptions: {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
        subOptions: [{
            resize: false,
            drag: true,
            rotate: false,
            keepRatio: false,
        }, {
            resize: true,
            drag: true,
            rotate: false,
            keepRatio: true,
        }]
    },
}

export default Plugin
import React from 'react'
import './index.less'
import { Space } from 'antd'
import CX from 'classnames'

export default function NavigatorButton({
    style = {},
    type = 'blue', // blue | normal | login
    content = '',
    onClick = () => {},
    icon = null
}) {
    const wrapStyle = Object.assign({
        width: 108,
        height: 30
    }, style)
    return (
        <div
            style={wrapStyle}
            className={CX({
                "nav-button": true,
                normal: type === 'normal',
                login: type === 'login',
                blue: type === 'blue',
                'items-center': true,
                'justify-center': true,
            })}
            onClick={onClick}
        >
            <Space>
                {icon}
                {content}
            </Space>
        </div>
    )
}
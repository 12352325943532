import React, { useRef, useCallback, useEffect, useImperativeHandle } from 'react'
// import { InOrOutViewObserver } from '@conecli/cone-render/components'

import Shade from "../../components/Shade"

export default function Preview({
    rectOptions,
    props,
    zIndex = 0,
    floorData,
}, ref) {
    const { top, left, width, height } = rectOptions
    const { flipImageList, interval, origin_data } = props
    // console.log(flipImageList,'flipImageList=====');
    // const shadeRef = useRef([])

    // const allShadeChange = (useLayout) => {
    //     shadeRef.current.forEach((item, index) => {
    //         item.shadeChange(useLayout === true ? index * 100 : 0)
    //     })
    // }

    // useImperativeHandle(ref, () => ({
    //     allShadeChange,
    // }))

    return (
        <div
            style={{
                width: width,
                height: height,
                position: 'absolute',
                left: left,
                top: top,
                display: 'flex',
                flexWrap: 'wrap',
            }}
        >
            {
                flipImageList.map((item, index) => {
                    return (
                        <Shade
                            // ref={(ref) => (shadeRef.current[index] = ref)}
                            list={[{
                                image: item.imageUrl1,
                            }, {
                                image: item.imageUrl2
                            }]}
                            onClick={(idx) => {
                                window.open(item[`clickUrl${idx + 1}`], floorData)
                                // handlePress(item[`clickUrl${idx + 1}`], floorData)
                            }}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: item.imageWidth,
                                height: item.imageHeight,
                            }}
                        />
                    )
                })
            }
        </div>
    )
}
import React from 'react'
import './index.less'

export default function Banner({ rectOptions, props }) {
    const { width, height, left, top } = rectOptions
    const { mediaUrl, poster, mask } = props.banners[0]
    return (
        <div className="banner-comp-wrap"
            style={{
                width,
                height,
                transform: `translate(${left}px, ${top}px)`
            }}
        >
            {
                mediaUrl.indexOf('.mp4') > 0 ? (
                    <div className="media">
                        <video
                            src={mediaUrl}
                            muted
                            autoPlay
                            poster={poster}
                        ></video>
                        <img src={mask} alt="" />
                    </div>
                   
                ) : (
                    <img className="media" src={mediaUrl} alt="" />
                )
            }
        </div>
    )
}

export function Dot({ rectOptions, props }) {
    const { height } = rectOptions.subRectOptions[0]
    const {
        defaultWidth,
        activeWidth,
        between,
        radius,
        defaultColor,
        activeColor
    } = props.subProps[0]
    
    return (
        <div
            className="items-center"
            style={{
                // width: (props.banners.length - 1) * (defaultWidth + between) + activeWidth,
                height,
            }}
        >
            {
                props.banners.map((v, i) => {
                    return (
                        <div
                            key={'dot_normal-banner' + i}
                            style={{
                                width: i === 0 ? activeWidth : defaultWidth,
                                height: '100%',
                                marginLeft: i === 0 ? 0 : between,
                                borderRadius: radius,
                                backgroundColor: i === 0 ? activeColor : defaultColor
                            }}
                        >

                        </div>
                    )
                })
            }
        </div>
    )
}

export function ScrollDot({
    rectOptions,
    props,
}) {
    const { height } = rectOptions.subRectOptions[0]
    const {
        defaultWidth,
        activeWidth,
        between,
        radius,
        defaultColor,
        activeColor,
    } = props.subProps[0]
    const step = defaultWidth / props.banners.length
    return (
        <div
            style={{
                width: defaultWidth,
                height,
                overflow: 'hidden',
                borderRadius: radius,
                backgroundColor: defaultColor,
            }}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: activeColor,
                    borderRadius: radius,
                    transform: `translateX(-${defaultWidth - step * 1}px)`
                }}
            ></div>
        </div>
    )
}

export function ScrollDotOrNormal ({
    rectOptions,
    props,
}) {
    const { top, left } = rectOptions.subRectOptions[0]
    return (
        <div
            style={{
                position: 'absolute',
                transform: `translate(${left}px, ${top}px)`,
            }}
        >
            {
                Number(props.subProps[0].type) === 0 ?
                    <Dot rectOptions={rectOptions} props={props} />
                    :
                    //二期模块剩余bugscrollDot
                    <ScrollDot rectOptions={rectOptions} props={props} />
            }
        </div>
    )
}

export const MutedBtn = ({ rectOptions, props }) => {
    const { subProps } = props
    const { mutedImgUrl, unmutedImgUrl, muted } = subProps[1]
    const { width, left, top, height } = rectOptions.subRectOptions[1]
    return (
        <div
            style={{
                position: 'absolute',
                width,
                height,
                transform: `translate(${left}px, ${top}px)`,
            }}
        >
            <img
                src={muted ? mutedImgUrl : unmutedImgUrl}
                alt=""
                style={{
                    width: '100%',
                    height: '100%',
                }}
            />
        </div>
    )
}
import React from 'react'
import { Space } from 'antd'
import '@/plugins/desc.less'

import {
    Input,
    Radio,
    ConfigTitle,
} from '@/components'
import { InputNumber3x, InputWithImg } from '@/HOC'

export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
}) {
    const { props, rectOptions } = currentPlugin
    const subRectOptions = rectOptions.subRectOptions[0]
    const subProps = props.subProps[0]
    return (
        <div className="desc-wrap">
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="视频组件"
                detail="视频默认无声音，点击声音按钮可开启声音"
            />
            <div className="desc-board">
                <Space direction='vertical' size={10}>
                <ConfigTitle
                        title="视频设置"
                        style={{
                            height: 21,
                        }}
                        titleSize={14}
                        detailSize={0}
                    />
                    <Input
                        labelContent="视频链接"
                        defaultValue={props.videoUrl}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                videoUrl: v,
                            })
                        }}
                    />
                    <InputWithImg
                        labelContent="视频封面链接"
                        defaultValue={props.posterUrl}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                posterUrl: v,
                            })
                        }}
                    />
                    <InputWithImg
                        labelContent="蒙层图片链接"
                        defaultValue={props.maskUrl}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                maskUrl: v,
                            })
                        }}
                    />
                    <Input
                        labelContent="跳转链接"
                        defaultValue={props.jumpUrl}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                jumpUrl: v,
                            })
                        }}
                    />
                    <Space>
                        <InputNumber3x
                            labelContent="宽"
                            defaultValue={rectOptions.width}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    width: v
                                })
                            }}
                        />
                        <InputNumber3x
                            labelContent="高"
                            defaultValue={rectOptions.height}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    height: v
                                })
                            }}
                        />
                    </Space>
                    <Space>
                        <InputNumber3x
                            labelContent="上边距"
                            defaultValue={rectOptions.top}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    top: v
                                })
                            }}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            defaultValue={rectOptions.left}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    left: v
                                })
                            }}
                        />
                    </Space>
                    <InputNumber3x
                        labelContent="圆角"
                        defaultValue={props.radius}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                radius: v
                            })
                        }}
                    />
                </Space>
            </div>
            <div className="desc-board">
                <Space direction='vertical' size={10}>
                    <ConfigTitle
                        title="声音开关设置"
                        style={{
                            height: 21,
                        }}
                        titleSize={14}
                        detailSize={0}
                    />
                    <InputWithImg
                        labelContent="静音图片链接"
                        defaultValue={subProps.mutedImgUrl}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                mutedImgUrl: v,
                            }, 1)
                        }}
                    />
                    <InputWithImg
                        labelContent="放声图片链接"
                        defaultValue={subProps.unmutedImgUrl}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                unmutedImgUrl: v,
                            }, 1)
                        }}
                    />
                    <Space>
                        <InputNumber3x
                            labelContent="宽"
                            defaultValue={subRectOptions.width}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    width: v
                                }, 1)
                            }}
                        />
                        <InputNumber3x
                            labelContent="高"
                            defaultValue={subRectOptions.height}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    height: v
                                }, 1)
                            }}
                        />
                    </Space>
                    <Space>
                        <InputNumber3x
                            labelContent="上边距"
                            defaultValue={subRectOptions.top}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    top: v
                                }, 1)
                            }}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            defaultValue={subRectOptions.left}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    left: v
                                }, 1)
                            }}
                        />
                    </Space>
                          
                </Space>
            </div>
        </div>
    )
}
import React from 'react'
import schema from './schema.json'
import { parseDrawUIProps } from '../schemaUtils'
import * as pluginUIComp from '../_component/drawComponent'

const { draw_ui, data } = schema

// TODO 暂时没考虑子组件
const drawUIData = draw_ui[0]
const render = {
    Comp: pluginUIComp[drawUIData.type],
    props: {}
}

function Comp({
    rectOptions,
    props,
}) {
    const { width, height, left, top } = rectOptions
    // TODO 封装起来
    if (drawUIData.type === 'ImgGroup') {
        Object.keys(drawUIData.props).forEach((k, i) => {
            if (drawUIData.props[k].__array__) {
                const arr = parseDrawUIProps(Object.assign(rectOptions, props), drawUIData.props[k].__array__)
                const result = []
                arr.forEach((item) => {
                    const newValue = {}
                    const arrInterface = drawUIData.props[k].interface
                    Object.keys(arrInterface).forEach((interfaceKey) => {
                        if (arrInterface[interfaceKey].indexOf('__array__') > -1) {
                            newValue[interfaceKey] = parseDrawUIProps(item, arrInterface[interfaceKey].split('.').slice(1, Infinity).join('.'))
                        } else {
                            newValue[interfaceKey] = parseDrawUIProps(Object.assign(rectOptions, props), arrInterface[interfaceKey])
                        }
                    })
                    result.push(newValue)
                })
                render.props[k] = result
            } else {
                if (typeof drawUIData.props[k] === 'object') {
                    render.props[k] = drawUIData.props[k]
                } else {
                    render.props[k] = parseDrawUIProps(Object.assign(rectOptions, props), drawUIData.props[k])
                }
            }
        })
    } else {
        Object.keys(drawUIData.props).forEach((k, i) => {
            // 产品规定rectOptions与props的键名不相同
            render.props[k] = parseDrawUIProps(Object.assign(rectOptions, props), drawUIData.props[k])
        })
    }

    console.log(width, height, 'llllllllll')

    return <div
        style={{
            position: 'absolute',
            transform: `translate(${left}px, ${top}px)`,
            width: !isNaN(Number(width)) ? Number(width) : 'auto',
            height: !isNaN(Number(height)) ? Number(height) : 'auto',
        }}
    >
        <render.Comp
            {...render.props}
        />
    </div>
}



export default Comp
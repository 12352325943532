import React, { useState } from 'react'
import { Space } from 'antd'
import '@/plugins/desc.less'
import {
    Input,
    Slider,
    Select,
    ConfigTitle,
} from '@/components'
import { InputNumber3x, Slider3x, InputWithImg } from '@/HOC'

export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
    deleteInsertPlugin,
}) {
    const { props, rectOptions, extension } = currentPlugin
    const { animateDistDuration, animateTimeDuration } = extension
    const [currentIndex, setCurrentIndex] = useState(0)
    const curSubProps = props.subProps[currentIndex]
    const curSubRect = rectOptions.subRectOptions[currentIndex]
    return (
        <div
            className="desc-wrap"
        >
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 10px'
                }}
                title="箭头组件"
                detail="点击向前或向后切换一帧轮播"
            />
            <div className="desc-board">
                <Space
                    style={{
                        padding: '0px 10px',
                    }}
                    align="start"
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="箭头素材设置"
                        detail="左右箭头需分别设置"
                        style={{
                            height: 37,
                        }}
                        titleSize={14}
                        detailSize={12}
                    />
                    <Select
                        chooseList={['左箭头', '右箭头']}
                        onClick={(idx) => {
                            setCurrentIndex(idx)
                        }}
                        currentIndex={currentIndex}
                    />
                    <InputWithImg
                        labelContent="图片链接"
                        defaultValue={curSubProps.imageUrl}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                imageUrl: e,
                            }, currentIndex + 1)
                        }}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                curSubRect.width = val
                                changeCurrentPluginRect(curSubRect, currentIndex + 1)
                            }}
                            defaultValue={curSubRect.width}
                        />
                        <InputNumber3x
                            labelContent="高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                curSubRect.height = val
                                changeCurrentPluginRect(curSubRect, currentIndex + 1)
                            }}
                            defaultValue={curSubRect.height}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                curSubRect.top = val
                                changeCurrentPluginRect(curSubRect, currentIndex + 1)
                            }}
                            defaultValue={curSubRect.top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                curSubRect.left = val
                                changeCurrentPluginRect(curSubRect, currentIndex + 1)
                            }}
                            defaultValue={curSubRect.left}
                        />
                    </Space>
                </Space>
            </div>
            <div className="desc-board">
                <Space
                    align="start"
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="动画设置"
                        detail=""
                        style={{
                            height: 37,
                        }}
                        titleSize={14}
                        detailSize={12}
                    />
                    <Slider3x
                        title="动画幅度"
                        min={animateDistDuration[0]}
                        max={animateDistDuration[1]}
                        defaultValue={props.animateDist}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                animateDist: v,
                            })
                        }}
                        suffix="px"
                    />

                    <Slider
                        title="动画时间"
                        min={animateTimeDuration[0]}
                        max={animateTimeDuration[1]}
                        defaultValue={props.animateTime}
                        suffix="秒"
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                animateTime: v
                            })
                        }}
                    />
                </Space>
            </div>
        </div>
    )
}
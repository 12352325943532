import Comp, { Dot } from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '左右滑动',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [Dot],
    type: 'slide-banner',
    props: {
        banners: [{
            imageUrl: '//img30.360buyimg.com/zx/jfs/t1/100020/5/38609/44708/64096c1aF8ca20642/4e31ec08d9755c54.png',
            hotArea: [],
            },
            {
            imageUrl: '//img12.360buyimg.com/zx/jfs/t1/183963/37/33632/42776/64096c0cFa52e7109/286ff1df3921dac9.png',
            hotArea: [],
            },
            {
            imageUrl: '//img12.360buyimg.com/zx/jfs/t1/208860/20/30225/43443/64096bf8Ff0a332ee/a9f2ea1685fd1607.png',
            hotArea: [],
            }
        ],
        imgWidth: 200,
        imgHeight: 400,
        slideWidth: 375,
        shrinkScale: 0.8,
        opacity: 0.8,
        interval: 3,
        subProps: [
            {
                showNumber: 0,
                defaultWidth: 30,
                activeWidth: 50,
                between: 5,
                radius: 4,
                defaultColor: '#000000',
                activeColor: '#ffffff',
            }
        ],
    },
    extension: {
        intervalDuration: [1, 10],
        slideWidthDuration: [0, 3000],
        struct: {
            imageUrl: '',
            hotArea: [],
        },
    },
    rectOptions: {
        width: 200,
        height: 200,
        left: 0,
        top: 100,
        rotate: 0,
        subRectOptions: [
            {
                width: 200,
                height: 10,
                left: 120,
                top: 460,
                rotate: 0,
            },
        ]
    },
    drawOptions: {
        resize: false,
        drag: true,
        rotate: false,
        keepRatio: false,
        subOptions: [
            {
                resize: false,
                drag: true,
                rotate: false,
                keepRatio: false,
                // renderDirections: ['n', 's']
            }
        ]
    },
}

export default Plugin
import React from 'react'
import { getDateDiffDHMS } from '@/utils'

const DD = ({ rectOptions, props }) => {
    const { left, top } = rectOptions.subRectOptions[0]
    let value = 0
    if (parseInt(props.startTime) > Date.now()) {
        value = getDateDiffDHMS(parseInt(props.startTime), Date.now())[0]
    } else if (Date.now() >= parseInt(props.startTime) && Date.now() < parseInt(props.endTime)) {
        value = getDateDiffDHMS(Date.now(), parseInt(props.endTime))[0]
    }
    const { fontSize, fontWeight, color, show } = props.subProps[0]
    return <div
        style={{
            display: show === false ? 'none' : 'block',
            position: 'absolute',
            fontSize,
            fontWeight,
            color,
            cursor: 'move',
            alignItems: 'center',
            display: 'flex',
            height: fontSize,
            transform: `translate(${left}px, ${top}px)`,
        }}
    >
        {value}
    </div>
}

const HH = ({ rectOptions, props }) => {
    const { left, top } = rectOptions.subRectOptions[1]
    const { fontSize, fontWeight, color, show } = props.subProps[1]
    let value = 0
    if (parseInt(props.startTime) > Date.now()) {
        value = getDateDiffDHMS(parseInt(props.startTime), Date.now())[1]
    } else if (Date.now() >= parseInt(props.startTime) && Date.now() < parseInt(props.endTime)) {
        value = getDateDiffDHMS(Date.now(), parseInt(props.endTime))[1]
    }
    return <div
        style={{
            display: show === false ? 'none' : 'block',
            position: 'absolute',
            fontSize,
            fontWeight,
            color,
            cursor: 'move',
            alignItems: 'center',
            display: 'flex',
            height: fontSize,
            transform: `translate(${left}px, ${top}px)`,
        }}
    >
        {value}
    </div>
}

const MM = ({ rectOptions, props }) => {
    const { left, top } = rectOptions.subRectOptions[2]
    const { fontSize, fontWeight, color, show } = props.subProps[2]
    let value = 0
    if (parseInt(props.startTime) > Date.now()) {
        value = getDateDiffDHMS(parseInt(props.startTime), Date.now())[2]
    } else if (Date.now() >= parseInt(props.startTime) && Date.now() < parseInt(props.endTime)) {
        value = getDateDiffDHMS(Date.now(), parseInt(props.endTime))[2]
    }
    return <div
        style={{
            display: show === false ? 'none' : 'block',
            position: 'absolute',
            fontSize,
            fontWeight,
            alignItems: 'center',
            display: 'flex',
            height: fontSize,
            color,
            cursor: 'move',
            transform: `translate(${left}px, ${top}px)`,
        }}
    >
        {value}
    </div>
}

const SS = ({ rectOptions, props }) => {
    const { left, top } = rectOptions.subRectOptions[3]
    const { fontSize, fontWeight, color, show } = props.subProps[3]
    let value = 0
    if (parseInt(props.startTime) > Date.now()) {
        value = getDateDiffDHMS(parseInt(props.startTime), Date.now())[3]
    } else if (Date.now() >= parseInt(props.startTime) && Date.now() < parseInt(props.endTime)) {
        value = getDateDiffDHMS(Date.now(), parseInt(props.endTime))[3]
    }
    return <div
        style={{
            display: show === false ? 'none' : 'block',
            position: 'absolute',
            fontSize,
            fontWeight,
            color,
            cursor: 'move',
            alignItems: 'center',
            display: 'flex',
            height: fontSize,
            transform: `translate(${left}px, ${top}px)`,
        }}
    >
        {value}
    </div>
}

export {
    DD,
    HH,
    MM,
    SS
}
import Comp, { MutedBtn } from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '视频组件',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [MutedBtn],
    type: 'video-comp',
    id: 'id7', // 测试
    props: {
        videoUrl: 'https://jvod.300hu.com/vod/product/e6c1cc3b-c948-4e6c-881c-11cd7add4e52/c01fed09c99a49f2ad5b5d7e4deeced0.mp4?source=2&h265=h265/18799/29eadccdd2204c8b92fc73fec387f7ae.mp4',
        posterUrl: '//img11.360buyimg.com/zx/jfs/t1/163450/10/30233/57635/63b3df14F050de248/031f56a5bf99065e.jpg',
        maskUrl: '//img12.360buyimg.com/zx/jfs/t1/79074/20/18365/635008/63b3e02eFd8150cef/964b3b5bece0ae09.png',
        jumpUrl: '',
        radius: 4,
        muted: true,
        subProps: [{
            mutedImgUrl: '//img10.360buyimg.com/imgzone/jfs/t1/226053/22/4717/607/65642a12Fefed02ad/08233b60ca616488.png',
            unmutedImgUrl: '//img10.360buyimg.com/imgzone/jfs/t1/236552/28/4624/601/65642a12Fa80102c4/25568bd4535620f0.png',
        }],
    },
    extension: {
    },
    rectOptions: {
        width: 375,
        height: 480,
        left: 0,
        top: 0,
        rotate: 0,
        subRectOptions: [{
            left: 10,
            top: 10,
            width: 20,
            height: 20,
            rotate: 0,
        }]
    },
    drawOptions: {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
        subOptions: [{
            resize: true,
            drag: true,
            rotate: false,
            keepRatio: false,
        }],
    },
}

export default Plugin
import Comp from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '无限滚屏',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [],
    type: 'loop-scroll',
    id: 'id10', // 测试
    props: {
        scrollList: [
            {
                imageUrl: '//img11.360buyimg.com/zx/jfs/t1/150215/14/12307/357569/5f97d057E15ad2443/71f7786c12e34d2f.png',
                hotArea: [],
            },
            {
                imageUrl: '//img20.360buyimg.com/zx/jfs/t1/143055/33/12295/330862/5f97d057Eb7729206/fbe3afb5f4525e82.png',
                hotArea: [],
            },
            {
                imageUrl: '//img14.360buyimg.com/zx/jfs/t1/148418/4/12395/359908/5f97d057Ec81b9976/be911b46f1f372a6.png',
                hotArea: [],
            }
        ],
        between: 20,
        scrollItemWidth: 375,
        scrollItemHeight: 426,
        type:0,
        scrollWrapWidth: 375,
        speed: 4,
        direction: 0, // 0: 左, 1: 右
        subProps: [],
    },
    extension: {
        scrollWidthDuration: [0, 1125],
        speedDuration: [1, 7],
        struct: {
            imageUrl: '',
            hotArea: [],
        }
    },
    rectOptions: {
        width: 375,
        height: 430,
        left: 0,
        top: 30,
        rotate: 0,
        subRectOptions: []
    },
    drawOptions: {
        resize: false,
        drag: true,
        rotate: false,
        keepRatio: false,
        subOptions: [],
    },
}

export default Plugin
import Comp from './comp'
import Preview from './preview'
import Desc from './desc'

const Plugin = {
    name: '动图',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [],
    type: 'git-img',
    id: 'id10086', // 测试
    props: {
        imageUrl: '//img11.360buyimg.com/zx/jfs/t1/32214/39/18654/37207/634d6057E725964c8/f7bda8891b8fd23f.png',
    },
    extension: {},
    rectOptions: {
        width: 375,
        height: 480,
        left: 0,
        top: 0,
        rotate: 0,
        subRectOptions: []
    },
    drawOptions: {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
        subOptions: []
    },
}

export default Plugin
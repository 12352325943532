import React, { useRef, useEffect, useState, useMemo } from 'react'
import HotArea from '../../components/HotArea'
import InfinityScroll from '../../components/InfinityScroll'
// import InfinityScroll from '../components/InfinityScrollScale'
// import { handlePress } from '../../utils'
// import { moduleUtil } from '@conecli/cone-render/api'
import _ from 'lodash'


const tgbClass = `tgb-class-${Date.now()}`
const tgbWrapClass = `tgb-class-wrap-${Date.now()}`

export default function InfinityScaleScroll({
    props,
    rectOptions,
    zIndex = 1,
    floorData,
    track = () => 0,
}) {
    const { activeIndex, imageWidth, imageHeight, scaleLoopScrollList, space, speed } = props
    const _activeIndex = Number(activeIndex)
    const { width, top, left } = rectOptions
    const infinityScrollRef = useRef(null)
    const domList = useRef([])
    const domWrapList = useRef([])
    const wrapElem = useRef(null)
    const len = 50
    //热区
    //const catchHotArea = useMemo(() => _.isEmpty(moduleUtil.getDataDefine(floorData, 'hotspotItemData#hotArea')) ? {data: []} : moduleUtil.getDataDefine(floorData, 'hotspotItemData#hotArea'), [])

    useEffect(() => {
        domList.current = document.querySelectorAll(`.${tgbClass}`)
        domWrapList.current = document.querySelectorAll(`.${tgbWrapClass}`)

    }, [])

    let  findSibling = ( tag )=>{
        let  parentEl = tag.parentNode ;
        let  childs = parentEl.children ;
        let  siblings = [];
        for( let i = 0; i <= childs.length - 1 ; i++ ){
            if( childs[i] === tag  ){
                continue ;
            }
            siblings[ siblings.length] = childs[i];
         }
         return siblings ;
    }

    
    return  <div
        ref={wrapElem}
        style={{
            display: 'flex',
            position: 'absolute',
            top: top,
            left: left,
            width: width,
            height: imageHeight,
            zIndex,
            overflowX: 'clip'
        }}>
        <InfinityScroll
            ref={infinityScrollRef}
            windowSize={{
                width: width,
                height: imageHeight,
            }}
            cloneLen={len}
            speed={(7 - speed) * 10}
            onScroll={(dist, direction = 'left') => {
                let wrapOffsetLeft = wrapElem.current.getBoundingClientRect().x
                const itemWidth = imageWidth + space
                const hiddenAreaDist = (len / 2 * scaleLoopScrollList.length - 1) * itemWidth
                const borderRight = hiddenAreaDist + width / 2 + itemWidth / 2 + _activeIndex * itemWidth
                const borderLeft = hiddenAreaDist + width / 2 - itemWidth / 2 + _activeIndex * itemWidth
                const maxScale = 1.1
                const step = (maxScale - 1) / itemWidth
                domWrapList.current.forEach((wrapDom, index) => {
                    let wrapDomLeft = wrapDom.getBoundingClientRect().x + ((len / 2 * scaleLoopScrollList.length) * (imageWidth) + space) - wrapOffsetLeft
                    const imageDom = domList.current[index]
                    const allHotAreaDom = findSibling(imageDom)
                    imageDom.scale = imageDom.scale ? imageDom.scale : 1
                    if (direction === 'right') {
                        if (wrapDomLeft < borderRight && wrapDomLeft > borderLeft) {
                            imageDom.scale = Math.max(1, imageDom.scale - step)
                            imageDom.style.transform = `scale(${imageDom.scale})`
                            allHotAreaDom.forEach((hotDom) => {
                                hotDom.style.transform = 'scale(1)'
                            })
                        } else if (wrapDomLeft >= borderRight && wrapDomLeft < (borderRight + itemWidth))  {
                            imageDom.style.transform = `scale(1)`
                            allHotAreaDom.forEach((hotDom, hotIndex) => {
                                hotDom.style.transform = 'scale(1)'
                            })
                        } else if (wrapDomLeft < borderLeft && wrapDomLeft >= borderLeft - itemWidth) {
                            imageDom.scale = Math.min(maxScale, imageDom.scale + step)
                            imageDom.style.transform = `scale(${imageDom.scale})`
                            allHotAreaDom.forEach((hotDom) => {
                                hotDom.style.transform = `scale(${maxScale})`
                            })
                        }
                    } else {
                        if (wrapDomLeft < borderRight && wrapDomLeft > borderLeft) {
                            imageDom.scale = Math.min(maxScale, imageDom.scale + step)
                            imageDom.style.transform = `scale(${imageDom.scale})`
                            allHotAreaDom.forEach((hotDom) => {
                                hotDom.style.transform = `scale(${maxScale})`
                            })
                        } else if (wrapDomLeft <= borderLeft && wrapDomLeft > borderLeft - itemWidth) {
                            imageDom.scale = Math.max(1, imageDom.scale - step)
                            imageDom.style.transform = `scale(${imageDom.scale})`
                            allHotAreaDom.forEach((hotDom) => {
                                hotDom.style.transform = 'scale(1)'
                            })
                        } else if (wrapDomLeft < borderLeft - itemWidth || wrapDomLeft > borderRight) {
                            imageDom.style.transform = `scale(1)`
                            allHotAreaDom.forEach((hotDom, hotIndex) => {
                                hotDom.style.transform = 'scale(1)'
                            })
                        }
                    }
                    
                })
            }}
        >
            {
                scaleLoopScrollList.map((item, index) => {
                    return <div
                        className={tgbWrapClass}
                        key={index}
                        style={{
                            position: 'relative',
                            flexShrink: 0,
                            width: imageWidth,
                            height: imageHeight,
                            marginLeft: space,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        onClick={() => {}}
                    >
                        <img
                            className={tgbClass}
                            style={{
                                position: 'relative',
                                width:'100%',
                                height:'100%',
                                flexShrink: 0,
                                transform: 'translateZ(0)'
                                // transition: '16ms linear',
                            }}
                            src={item.imageUrl}
                            alt=""
                        />
                        {
                            //item.hotArea.map((hot, hotIndex) => <HotArea track={track} hot={hot} key={hotIndex} style={{ zIndex: 2 }} floorData={floorData} catchPrice={catchHotArea.data[hotIndex] ? catchHotArea.data[hotIndex].price : ''} />)
                        }
                    </div>
                })
            }
        </InfinityScroll>
        
    </div>
}
import React, { useRef, useCallback, useEffect, useState, useImperativeHandle } from 'react'

export default React.forwardRef(function Preview({
    rectOptions,
    props,
    zIndex = 0,
    floorData,
    activeIndex = 0,
    onClick= () => {}
}, ref) {
    const { top, left } = rectOptions
    const { direction, navWidth, navHeight, navList, opacity, space } = props
    const [_activeIndex, _setActiveIndex] = useState(activeIndex)
    return (
        <div
            style={{
                position: 'absolute',
                top: top,
                left: left,
                display: 'flex',
                zIndex,
                flexDirection: direction === '垂直' ? 'column' : 'row'
            }}
        >
            {
                navList.map((nav, index) => {
                    return <div
                        key={index}
                        style={{
                            width: navWidth,
                            height: navHeight,
                            marginLeft: direction !== '水平' || index === 0 ? 0 : Number(space),
                            marginTop: direction === '水平' || index === 0 ? 0 : Number(space),
                        }}
                        onClick={() => {
                            onClick(index)
                        }}
                    >
                    </div>
                })
            }
        </div>
    )
})
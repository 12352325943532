import Comp from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '悬浮Icon',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [],
    type: 'float-icon',
    id: 'id10087', // 测试
    props: {
        imageUrl: '//img13.360buyimg.com/zx/jfs/t1/148078/2/12596/46051/5f9b76cbE743d2b0c/f71065e96677f663.png',
        jumpUrl: '',
        animateTime: 2,
        animateRange: 30,
        subProps: [],
    },
    extension: {
        animateTimeDuration: [1, 10],
        animateRangeDuration: [0, 90],
    },
    rectOptions: {
        width: 74,
        height: 68,
        left: 288,
        top: 375,
        rotate: 0,
        subRectOptions: []
    },
    drawOptions: {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
        subOptions: [],
    },
}

export default Plugin
import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper'
import './swiper.css'
import './swiper-element.css'

export default function ShopInform({
    rectOptions,
    props,
    zIndex,
}) {
    let { width, height, left, top } = rectOptions
    console.log(props, 'rks-a-ss');
    let { textList, fontSize, fontWeight, color, interval } = props
    width = width
    height = height
    left =  left
    top = top
    fontSize = fontSize

    console.log(interval, 'interval')

    return <div
        style={{
            width,
            height,
            left,
            top,
            position: 'absolute',
            zIndex,
        }}
    >
        <div
            style={{
                width: '100%',
                height: '100%',
            }}
            scrollX
        >
            <Swiper
                modules={[Autoplay]}
                loop
                autoplay={{
                    delay: interval,
                    disableOnInteraction: false
                }}
                style={{
                    width: '100%',
                    height: '100%',
                }}
                direction="vertical"
            >
                {
                    textList.map(({ content }, index) => {
                        return <SwiperSlide key={index}>
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    fontSize,
                                    fontWeight,
                                    color,
                                }}
                            >
                                {content}
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </div>

    </div>
}
import React, { useState, useContext } from 'react'
import { Space } from 'antd'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import '@/plugins/desc.less'
import {
    Input,
    ConfigTitle,
    BaseButton,
    Select,
} from '@/components'
import { InputNumber3x, HotArea, InputWithImg } from '@/HOC'
import { BaseConfigContext } from '@/context'

export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
}) {
    const [currentIndex, setCurrentIndex] = useState(0)
    const { maxSize } = useContext(BaseConfigContext)

    const { props, rectOptions, extension } = currentPlugin
    const { subProps } = props
    const { subRectOptions } = rectOptions
    const expandHeight = subRectOptions[0].height
    const { banners, imageWidth, imageHeight } = subProps[0]
    const curBanner = banners[currentIndex]
    
    return (
        <div className="desc-wrap">
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="展开收起"
                detail="一键展开，轻松收起"
            />
            <div className="desc-board">
                <Space direction="vertical" size={16}>
                    <ConfigTitle
                        title="展开收起按钮设置"
                        style={{
                            height: 21,
                        }}
                        titleSize={14}
                        detailSize={12}
                    />
                    <InputWithImg
                        labelContent="点击展开图片链接"
                        defaultValue={props.showImgUrl}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                showImgUrl: e,
                            })
                        }}
                    />
                    <InputWithImg
                        labelContent="点击收起图片链接"
                        defaultValue={props.hideImgUrl}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                hideImgUrl: e,
                            })
                        }}
                    />
                    
                    <Space size={10}>
                        <BaseButton
                            content="使用图片实际尺寸"
                            type="blue"
                            style={{
                                width: 155,
                                backgroundColor: '#477AFF'
                            }}
                            onClick={() => {
                                const _image = new Image()
                                _image.src = props.showImgUrl
                                _image.onload = (e) => {
                                    const _imgWidth = e.target.width
                                    const _imgHeight = e.target.height
                                    const _ratio = _imgWidth / _imgHeight
                                    changeCurrentPluginRect({
                                        width: maxSize.width,
                                        height: Math.min(maxSize.height, maxSize.width / _ratio)
                                    })
                                }
                            }}
                        />
                        <InputNumber3x
                            labelContent="高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.height = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.height}
                        />
                    </Space>
                </Space>
            </div>
            <div className="desc-board">
                <Space direction="vertical" size={16} align="start">
                    <ConfigTitle
                        title="展开区域设置"
                        detail="每张图片可单独编辑热区"
                        style={{
                            height: 37,
                        }}
                        titleSize={14}
                        detailSize={12}
                    />
                    <Select
                        chooseList={banners.map((v, i) => `第${i + 1}组`)}
                        onClick={(idx) => {
                            setCurrentIndex(idx)
                        }}
                        currentIndex={currentIndex}
                    />
                    <InputWithImg
                        labelContent="图片链接"
                        defaultValue={curBanner.imageUrl}
                        onChange={(e) => {
                            const list = [...banners]
                            list[currentIndex].imageUrl = e
                            updateCurrentPluginProps({
                                banners: list,
                            }, 1)
                        }}
                    />
                    <HotArea
                        editContentSize={{
                            width: imageWidth,
                            height: imageHeight,
                        }}
                        contentFit="fill"
                        defaultValue={curBanner.hotArea}
                        editContent={curBanner.imageUrl}
                        onSave={(data) => {
                            const list = [...banners]
                            list[currentIndex].hotArea = data
                            updateCurrentPluginProps({
                                banners: list,
                            }, 1)
                        }}
                    />
                    <Space>
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<DeleteFilled />}
                            content="删除本组"
                            onClick={() => {
                                const list = [...banners]
                                if (list.length > 1) {
                                    list.splice(currentIndex, 1)
                                    setCurrentIndex(0)
                                    updateCurrentPluginProps({
                                        banners: list,
                                    }, 1)
                                }
                            }}
                        />
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<PlusOutlined />}
                            content="添加帧数"
                            onClick={() => {
                                const list = [...banners]
                                list.push(Object.assign({}, extension.struct))
                                updateCurrentPluginProps({
                                    banners: list
                                }, 1)
                            }}
                        />
                    </Space>

                    <Space direction="vertical" align="start">
                        <span style={{ fontSize: 12, color: '#999' }}>单张图片宽高:</span>
                        <Space size={10}>
                            <InputNumber3x
                                labelContent="宽"
                                size="middle"
                                style={{
                                    color: '#696969',
                                }}
                                onChange={(val) => {
                                    updateCurrentPluginProps({
                                        imageWidth: val,
                                    }, 1)
                                }}
                                defaultValue={imageWidth}
                            />
                            <InputNumber3x
                                labelContent="高"
                                size="middle"
                                style={{
                                    color: '#696969',
                                }}
                                onChange={(val) => {
                                    updateCurrentPluginProps({
                                        imageHeight: val,
                                    }, 1)
                                }}
                                defaultValue={imageHeight}
                            />
                        </Space>
                    </Space>
                    <Space direction="vertical" align="start">
                        <span style={{ fontSize: 12, color: '#999' }}>展开区域高度:</span>
                        <InputNumber3x
                            labelContent="高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                changeCurrentPluginRect({
                                    expandHeight: val,
                                }, 1)
                            }}
                            defaultValue={expandHeight}
                        />
                    </Space>

                </Space>
            </div>
        </div>
    )
}
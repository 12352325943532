import React from 'react'

export default function Comp({
    props,
    rectOptions,
    currentIndex
}) {
    const { subRectOptions } = rectOptions
    const { width, height, left, top } = subRectOptions[currentIndex]
    const { flyImageList } = props
    // console.log(flyImageList,'flyImageList=======');
    // todu:这个1不是固定数值，如果要适配的话还需要转换数值
    return width < 1 && height < 1 ? <></> : <div
    style={{
        position: 'absolute',
        width,
        height,
        overflow: 'hidden',
        transform: `translate(${left}px, ${top}px)`,
    }}
>
    <img
        src={flyImageList[0][`imageUrl1`]}
        alt=""
        style={{
            width: '100%',
            height: '100%',
        }}
    /> 
</div>
}
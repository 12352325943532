import Comp from './comp'
import Desc from './desc'
import Preview from './preview'

const defaultRectOptions = {
    width: 375,
    height: 667,
    left: 0,
    top: 0,
    rotate: 0,
    subRectOptions: []
}

const defaultDrawOptions = {
    resize: true,
    drag: true,
    rotate: false,
    keepRatio: false,
    renderDirections: ["nw","n","ne","w","e","sw","s","se"],
    subOptions: [],
}
const plugin = {
    name: '飞入图带热区',
    type: 'fly-image-hot-area',
    render: () => <></>,
    board: Desc,
    preview: Preview,
    subPlugins: [Comp, Comp],
    props: {
        subProps: [
            {
                imageUrl: 'https://th.bing.com/th?id=OIP.Cdq25dINGG8gky7W0x8XaQHaE7&w=306&h=204&c=8&rs=1&qlt=90&o=6&dpr=2&pid=3.1&rm=2',
                "flyDirection": "左",
                flyDistance: 300,
                flyTime: 2,
                animateType: '放大缩小',
                animateScope: 20,
                animateRate: 3,
                hotArea: [],
                scrollDistance: 30,
                scale: 0.8,
            },
            {
                "imageUrl": "https://th.bing.com/th?id=OIP.6szqS1IlGtWsaiHQUtUOVwHaQC&w=161&h=349&c=8&rs=1&qlt=90&o=6&dpr=2&pid=3.1&rm=2",
                "flyDirection": "右",
                flyDistance: 300,
                flyTime: 2,
                animateType: '放大缩小',
                animateScope: 20,
                animateRate: 3,
                hotArea: [],
                scrollDistance: 30,
                scale: 0.8,
            }
        ],
    },
    extension: {
        subLen: 2,
        flyDistanceRange: [0, 500],
        flyTimeRange: [0, 10],
        animateScopeRange: [0, 90],
        animateRateRange: [0, 10],
        scrollDistanceRange: [0, 375],
        animateTypeRadioList: [
            "左右滑动",
            "上下滑动",
            "放大缩小"
        ],
        flyDirectionRadioList: [
            "左",
            "右",
            "上",
            "下"
        ]
    },
    rectOptions: Object.assign(defaultRectOptions, {
        subRectOptions: [
            {
                "width": 100,
                "height": 100,
                "top": 160,
                "left": -90,
            },
            {
                "width": 200,
                "height": 100,
                "top": 160,
                "left": 100,
            }
        ]
    }),
    drawOptions: Object.assign(defaultDrawOptions, {
        subOptions: [
            {
                resize: true,
                drag: true,
                rotate: false,
                keepRatio: false,
            },
            {
                resize: true,
                drag: true,
                rotate: false,
                keepRatio: false,
            },
        ]
        
    })
}

export default plugin
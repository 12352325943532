import React, { useState, useRef, useImperativeHandle } from 'react'

export default React.forwardRef(function Flip({
    list,
    style = {},
    onClick = () => {},
    floorData = {},
    track = () => 0,
}, ref) {
    const animateDuration = 300
    const rotateTime = useRef(0)
    const flipChange = (timeout) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                setRotate(rotate + 180)
                setTimeout(() => {
                    rotateTime.current += 1
                    setActiveIndex(activeIndex + 1 >= list.length ? 0 : activeIndex + 1)
                }, animateDuration / 2)
                setTimeout(() => {
                    resolve()
                }, animateDuration)
            }, timeout)
        })
        
    }
    const [activeIndex, setActiveIndex] = useState(0)
    const [rotate, setRotate] = useState(0)
    const { image, link } = list[activeIndex]
    useImperativeHandle(ref, () => ({
        flipChange
    }))
    return <div
        style={Object.assign({
            transform: `rotateY(${rotate}deg)`,
            transition: `${animateDuration}ms linear`,
        }, style)}
        onClick={() => {
            onClick(activeIndex)
        }}
        reportData={[floorData, {
            pos: track(),
            jumpUrl: encodeURIComponent(link ?? '')
        }, {}]}
    >
        <div
            style={{
                transform: `rotateY(${rotateTime.current % 2 === 1 ? 180 : 0}deg)`
            }}
        >
            <img
                style={{
                    width: '100%',
                    height: '100%',
                }}
                src={image}
            />
        </div>


    </div>
})
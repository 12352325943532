import React, { useRef, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/swiper-element.min.css'
import 'swiper/swiper-element-bundle.min.css'
import 'swiper/swiper-bundle.min.css'


export default function Preview({
    rectOptions,
    props,
    currentIndex = 0,
    floorData,
    zIndex = 0,
    track = () => 0,
}) {
    const timer = useRef(null)
    const flag = useRef(true)
    const imageListRef = useRef([])
    const { width, height, left, top } = rectOptions
    let { scaleBannerList, animateScale, animateDuration } = props
    const banners = scaleBannerList
    const [activeIndex, setActiveIndex] = useState(currentIndex)
    const [isFirstImageLoaded, setIsFirstImageLoaded] = useState(false)
    useEffect(() => {
        setActiveIndex(currentIndex)
    }, [currentIndex])
    useEffect(() => {
        if (isFirstImageLoaded) {
            clearInterval(timer.current)
            flag.current = true
            imageListRef.current.forEach((elem, index) => {
                elem.style.transform = 'scale(1)'
            })
            imageListRef.current[activeIndex].style.transform = `scale(${flag.current ? animateScale : 1})`
            timer.current = setInterval(() => {
                flag.current = !flag.current
                imageListRef.current[activeIndex].style.transform = `scale(${flag.current ? animateScale : 1})`
            }, 2000)
        }
    }, [activeIndex, isFirstImageLoaded])
    return (
        <>
            <div
                style={{
                    width: width,
                    height: height,
                    left: left,
                    top: top,
                    zIndex,
                    position: 'absolute',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    scrollX
                >
                    <Swiper
                        modules={[Autoplay]}
                        loop
                        autoplay={{
                            delay: animateDuration * 1000,
                            disableOnInteraction: false
                        }}
                        onSlideChangeTransitionEnd={(e) => {
                            setActiveIndex(e.realIndex)

                        }}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        onSlideChange={(e) => {
                        }}
                    >
                        {
                            banners.map((banner, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                            }}
                                            onClick={() => {
                                                window.open(banner.clickUrl)
                                            }}
                                            reportData={[floorData, {
                                                pos: track(),
                                                jumpUrl: encodeURIComponent(banner.clickUrl)
                                            }]}
                                        >
                                            <img
                                                ref={(r) => { imageListRef.current[index] = r }}
                                                src={banner.imageUrl}
                                                alt=""
                                                onLoad={() => {
                                                    if (index === 0) {
                                                        setIsFirstImageLoaded(true)
                                                    }
                                                }}
                                                style={{
                                                    transition: activeIndex === index ? '2000ms' : '0ms',
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                                onTransitionEnd={() => {
                                                    // setScale(scale === 1 ? animateScale : 1)
                                                }}
                                            />
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>

            </div>

        </>

    )
}
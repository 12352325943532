import React from 'react'

export default function Preview({
    rectOptions,
    props,
}) {
    const { width, height, top, left } = rectOptions
    const { imageUrl } = props // 试例，正式模版中会删掉
    return (
        <div
            style={{
                position: 'absolute',
                width: width, // 单位px
                height: height, // 单位px
                left,
                top,
            }}
        >
            <img
                src={imageUrl}
                alt=""
                style={{
                    width,
                    height,
                }}
            />
        </div>
    )
    
}
import React from 'react'

export default function Comp({
    props,
    rectOptions,
}) {
    const { left, top, width, height } = rectOptions
    const {
        bannerWidth,
        between,
        banners,
    } = props
    return (
        <div
            style={{
                position: 'absolute',
                width,
                height,
                overflow: 'hidden',
                transform: `translate(${left}px, ${top}px)`
            }}
        >
            {
                banners.slice(0, 3).map((banner, index) => {
                    return (
                        <div
                            style={{
                                position: 'absolute',
                                zIndex: banners.length - index,
                                width: bannerWidth,
                                height,
                                transform: `translateX(${between * index}px) scaleY(${1 - index * 0.1})`
                            }}
                        >
                            <img
                                src={banner.imageUrl}
                                alt=""
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}
import Comp from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '手风琴',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [],
    type: 'accordition-banner',
    props: {
        banners: [{
            imageUrl: '//img11.360buyimg.com/zx/jfs/t1/150215/14/12307/357569/5f97d057E15ad2443/71f7786c12e34d2f.png',
            jumpUrl: '',
        }, {
            imageUrl: '//img20.360buyimg.com/zx/jfs/t1/143055/33/12295/330862/5f97d057Eb7729206/fbe3afb5f4525e82.png',
            jumpUrl: '',
        }, {
            imageUrl: '//img14.360buyimg.com/zx/jfs/t1/148418/4/12395/359908/5f97d057Ec81b9976/be911b46f1f372a6.png',
            jumpUrl: '',
        }],
        bannerWidth: 300,
        between: 25,
        interval: 3,
    },
    extension: {
        struct: {
            imageUrl: '',
            jumpUrl: '',
        },
        scrollWidthDuration: [0, 1125],
        intervalDuration: [1, 10]
    },
    rectOptions: {
        width: 375,
        height: 341,
        left: 0,
        top: 0,
        rotate: 0,
        subRectOptions: []
    },
    drawOptions: {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
        subOptions: []
    },
}

export default Plugin
import React, { useRef, useCallback, useEffect, useImperativeHandle } from 'react'
import Flip from '../../components/Flip'
// import { rpx1x, handlePress } from '../../utils'

export default React.forwardRef(function Preview({
    rectOptions,
    props,
    zIndex = 0,
    floorData,
    track = () => 0,
}, ref) {
    const { top, left, width, height } = rectOptions
    const { flipImageList, interval, origin_data } = props
    const flipRef = useRef([])

    const allFlipChange = (useLayout) => {
        flipRef.current.forEach((item, index) => {
            item.flipChange(useLayout === true ? index * 100 : 0)
        })
    }
console.log(flipRef,'flipRef======s');
    useImperativeHandle(ref, () => ({
        allFlipChange,
    }))

    return (
        <div
            style={{
                width: width,
                height: height,
                position: 'absolute',
                left: left,
                top: top,
                display: 'flex',
                flexWrap: 'wrap',
                zIndex,
            }}
        >
            {
                flipImageList.map((item, index) => {
                    return (
                        <Flip
                            ref={(ref) => (flipRef.current[index] = ref)}
                            list={[{
                                image: item.imageUrl1,
                                link: item.clickUrl1
                            }, {
                                image: item.imageUrl2,
                                link: item.clickUrl2
                            }]}
                            // onClick={(idx) => {
                            //     handlePress(item[`clickUrl${idx + 1}`], floorData)
                            // }}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: item.imageWidth,
                                height: item.imageHeight,
                            }}
                            track={track}
                            floorData={floorData}
                        />

                    )
                })
            }
        </div>
    )
})
import React, { useState } from 'react'
import { Space } from 'antd'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import Comp from './comp'
import '@/plugins/desc.less'
import {
    Input,
    Slider,
    ConfigTitle,
    Select,
    BaseButton,
    Radio,
} from '@/components'
import { InputNumber3x, Slider3x, InputWithImg, HotArea } from '@/HOC'
import { InputNumWithTitle } from '../../HOC'

export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
    addSubPlugins,
    deleteInsertPlugin,
    deleteSubPlugins,
}) {
    const { props, rectOptions, extension, subPlugins } = currentPlugin
    const { subProps } = props
    const { subRectOptions } = rectOptions
    const [activeIndex, setActiveIndex] = useState(0)
    return (
        <div
            className="desc-wrap"
        >
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="飞入图组件"
                detail="仅在滑动到该楼层时飞入一次"
            />
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="飞入图素材设置"
                        detail="设置飞入图片及跳转链接"
                        style={{
                            height: 37,
                        }}
                        titleSize={14}
                        detailSize={12}
                    />
                    <Select
                        chooseList={subProps.map((v, i) => `第${i + 1}张`)}
                        onClick={(idx) => {
                            setActiveIndex(idx)
                        }}
                        currentIndex={activeIndex}
                    />
                    <InputWithImg
                        labelContent="图片链接"
                        defaultValue={subProps[activeIndex].imageUrl}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                imageUrl: e,
                            }, activeIndex + 1)
                        }}
                    />
                    <Input
                        labelContent="跳转链接"
                        defaultValue={subProps[activeIndex].clickUrl}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                clickUrl: e,
                            }, activeIndex + 1)
                        }}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            defaultValue={subRectOptions[activeIndex].width}
                            onChange={(e) => {
                                changeCurrentPluginRect({
                                    width: e,
                                }, activeIndex + 1)
                            }}
                        />
                        <InputNumber3x
                            labelContent="高"
                            defaultValue={subRectOptions[activeIndex].height}
                            onChange={(e) => {
                                changeCurrentPluginRect({
                                    height: e,
                                }, activeIndex + 1)
                            }}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            defaultValue={subRectOptions[activeIndex].top}
                            onChange={(e) => {
                                changeCurrentPluginRect({
                                    top: e,
                                }, activeIndex + 1)
                            }}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            defaultValue={subRectOptions[activeIndex].left}
                            onChange={(e) => {
                                changeCurrentPluginRect({
                                    left: e,
                                }, activeIndex + 1)
                            }}
                        />
                    </Space>
                    <Radio
                        title="飞入方向"
                        defaultValue={extension.flyDirectionRadioList.indexOf(subProps[activeIndex].flyDirection)}
                        radioList={extension.flyDirectionRadioList}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                flyDirection: extension.flyDirectionRadioList[v]
                            }, activeIndex + 1)
                        }}
                    />
                    <Slider3x
                        title="飞入距离"
                        suffix="px"
                        defaultValue={subProps[activeIndex].flyDistance}
                        min={extension.flyDistanceRange[0]}
                        max={extension.flyDistanceRange[1]}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                flyDistance: v
                            }, activeIndex + 1)
                        }}
                    />
                    <Radio
                        title="动画效果"
                        defaultValue={extension.animateTypeRadioList.indexOf(subProps[activeIndex].animateType)}
                        radioList={extension.animateTypeRadioList}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                animateType: extension.animateTypeRadioList[v]
                            }, activeIndex + 1)
                        }}
                    />
                    <InputNumWithTitle
                        labelContent="缩放倍数"
                        defaultValue={subProps[activeIndex].scale}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                scale: v
                            })
                        }}
                    />
                    <Slider3x
                        title="滑动距离"
                        suffix="px"
                        defaultValue={subProps[activeIndex].scrollDistance}
                        min={extension.scrollDistanceRange[0]}
                        max={extension.scrollDistanceRange[1]}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                scrollDistance: v
                            })
                        }}
                    />
                    <Slider
                        title="动画频率"
                        suffix="秒"
                        defaultValue={subProps[activeIndex].animateRate}
                        min={extension.animateRateRange[0]}
                        max={extension.animateRateRange[1]}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                animateRate: v
                            })
                        }}
                    />

                    <Space>
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<DeleteFilled />}
                            content="删除本组"
                            onClick={() => {
                                const list = [...subPlugins]
                                if (list.length === 1) {
                                    deleteInsertPlugin(currentPlugin.insertId)
                                } else {
                                    setActiveIndex(0)
                                    deleteSubPlugins(activeIndex)
                                }
                            }}
                        />
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<PlusOutlined />}
                            content="添加帧数"
                            onClick={() => {
                                addSubPlugins({
                                    comp: Comp,
                                    props: Object.assign({}, subProps[0]),
                                    rectOptions: Object.assign({}, subRectOptions[0], { left: 0, top: 0 })
                                })
                            }}
                        />
                    </Space>
                </Space>
            </div>
        </div>
    )
}
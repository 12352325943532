import React from 'react'
import { useRefreshMediaRect } from '@/utils/hooks'
import './index.less'

export default function HotBanner({ rectOptions, props }) {
    const { width, height, left, top } = rectOptions
    const { banners } = props
    useRefreshMediaRect(banners[0].imageUrl, rectOptions)
    return (
        <div
            className="hot-banner-wrap"
            style={{
                width,
                height,
                transform: `translate(${left}px, ${top}px)`,
            }}
        >
            <img src={banners[0].imageUrl} alt="" />
        </div>
    )
}
import Comp from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '图片切换',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [],
    type: 'img-banner',
    id: 'id2', // 测试
    props: {
        banners: [{
            imageUrl: '//img30.360buyimg.com/zx/jfs/t1/121697/12/17116/402477/5fa13848Eebff5399/78274fd96a731399.jpg',
            jumpUrl: '',
            },
            {
            imageUrl: '//img13.360buyimg.com/zx/jfs/t1/133580/14/14551/359604/5fa13848E40b6ed1c/05442e92397da342.jpg',
            jumpUrl: '',
            }
        ],
        duration: 4,
        direction: 0, // ['水平', '垂直']
        subProps: [],
    },
    extension: {
        durationSection: [1, 10],
        struct: {
            imageUrl: '',
            duration: 1,
            direction: 0, // ['水平', '垂直']
            hotArea: [],
        },
    },
    rectOptions: {
        width: 375,
        height: 293,
        left: 0,
        top: 174,
        rotate: 0,
        subRectOptions: []
    },
    drawOptions: {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
        subOptions: []
    },
}

export default Plugin
import React, { useState, useEffect } from 'react'
import './index.less'

export default function ColorPickerCustom({
    style = {},
    title = '默认颜色',
    pickerSize = {
        width: 20,
        height: 20,
    },
    defaultValue = '#000',
    onChange = () => {}
}) {
    const wrapStyle = Object.assign({
        width: 155,
        height: 34,
        padding: '0px 8px 0px 10px'
    }, style)

    const [value, setValue] = useState(defaultValue)
    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])

    return (
        <div
            style={wrapStyle}
            className="color-picker-wrap items-center justify-between"
        >
            <div className="title">{title}</div>
            <div className="picker items-center justify-center" style={pickerSize}>
                <input
                    style={{
                        width: pickerSize.width - 2,
                        height: pickerSize.height - 2,
                    }}
                    value={value}
                    className="input"
                    type="color"
                    onChange={(e) => {
                        onChange(e.target.value)
                    }}
                />
            </div>
        </div>
    )
}
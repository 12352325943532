import React, { useRef, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/swiper-element.min.css'
import 'swiper/swiper-element-bundle.min.css'
import 'swiper/swiper-bundle.min.css'

export default function Preview({
    rectOptions,
    props,
    onChange = () => { },
    currentIndex = 0,
    zIndex = 0
}) {
    const { width, height, left, top } = rectOptions
    const { banners, duration, direction } = props
    const swiperRef = useRef(null)
    const curIndexRef = useRef(currentIndex)
    useEffect(() => {
        if (currentIndex !== curIndexRef.current) {
            swiperRef.current.swiper.slideTo(currentIndex)
        }
    }, [currentIndex])
    return (
        <div
            style={{
                width,
                height,
                left,
                top,
                zIndex,
                position: 'absolute',
            }}
        >
            <Swiper
                style={{
                    width: '100%',
                    height: '100%',
                }}
                modules={[Autoplay]}
                loop
                direction={direction === 0 ? 'horizontal' : 'vertical'}
                autoplay={{
                    delay: duration * 1000,
                    disableOnInteraction: false
                }}
                onSlideChangeTransitionEnd={(e) => {
                }}
                onSlideChange={(e) => {
                    curIndexRef.current = e.realIndex
                    onChange(e.realIndex)
                }}
                effect="slide"
                ref={swiperRef}
            >
                {
                    banners.map((banner, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div
                                    style={{
                                        width,
                                        height,
                                        position: 'relative'
                                    }}

                                >
                                    <img
                                        src={banner.imageUrl}
                                        alt=""
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain'
                                        }}
                                    />
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </div>

    )
}
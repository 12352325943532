import React from 'react'
import './index.less'
import { useRefreshMediaRect } from '@/utils/hooks'

export default function AlertComp({ rectOptions, props }) {
    const { width, height, left, top } = rectOptions
    const { mediaUrl } = props
    useRefreshMediaRect(mediaUrl, rectOptions)
    return (
        <div className="alert-comp-wrap comp-wrap"
            style={{
                width,
                height,
                transform: `translate(${left}px, ${top}px)`
            }}
        >
            {
                mediaUrl.indexOf('.mp4') > -1 ? (
                    <video
                        src={mediaUrl}
                        autoPlay
                        muted
                    />
                ) : (
                    <img src={mediaUrl} alt="" />
                )
            }
            
        </div>
    )
}

export function CloseBtn({
    rectOptions, props
}) {
    const { width, height, left, top } = rectOptions.subRectOptions[0]
    const { closeImgUrl } = props.subProps[0]
    useRefreshMediaRect(closeImgUrl, props.subProps[0],1)
    return (
        <div
            className="close-btn comp-wrap"
            style={{
                width,
                height,
                transform: `translate(${left}px, ${top}px)`
            }}
        >
            <img src={closeImgUrl} alt="" />
        </div>
    )
}

export function CountDown({
    rectOptions,
    props,
}) {
    const { animateTime, showTime } = props
    const { left, top } = rectOptions.subRectOptions[1]
    const { fontSize, fontWeight, color } = props.subProps[1]
    return (
        <div
            className="count-down comp-wrap"
            style={{
                fontSize,
                fontWeight,
                color,
                transform: `translate(${left}px, ${top}px)`
            }}
        >{ animateTime + showTime }</div>
    )
}
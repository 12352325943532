import { LeftArrow, RightArrow } from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '箭头组件',
    render: () => <></>,
    board: Desc,
    preview: Preview,
    subPlugins: [LeftArrow, RightArrow],
    type: 'arrow-group-comp',
    props: {
        animateDist: 20,
        animateTime: 3,
        subProps: [
            {
                imageUrl: '//img20.360buyimg.com/zx/jfs/t1/124766/20/17074/3048/5fa0f31dE6297bc9a/9e0e5f076a5728d7.png',
            },
            {
                imageUrl: '//img12.360buyimg.com/zx/jfs/t1/125718/6/17209/3030/5fa0f31dEd04614ed/c5c9792ddde9202c.png'
            }
        ]
    },
    extension: {
        animateDistDuration: [0, 1125],
        animateTimeDuration: [0, 40],
    },
    rectOptions: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
        rotate: 0,
        subRectOptions: [{
            left: 0,
            width: 27,
            height: 35,
            top: 200,
            rotate: 0,
        }, {
            left: 348,
            top: 200,
            width: 27,
            height: 35,
            rotate: 0,
        }]
    },
    drawOptions: {
        resize: false,
        drag: false,
        rotate: false,
        keepRatio: false,
        subOptions: [{
            resize: true,
            drag: true,
            rotate: false,
            keepRatio: true,
        }, {
            resize: true,
            drag: true,
            rotate: false,
            keepRatio: true,
        }]
    },
}

export default Plugin
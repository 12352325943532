import React, { useState, useEffect } from 'react'
import { Slider, Input, InputNumber } from 'antd'
import { convertNumber } from '@/utils' 

import './index.less'

export default function SliderCustom({
    style = {
        width: 320,
    },
    title = '摆动幅度:',
    step = 1,
    max = 100,
    min = 0,
    suffix = '秒',
    defaultValue = 0,
    onChange = () => {},
}) {
    const [value, setValue] = useState(defaultValue)
    const handleChangeNum = (val) => {
        const result = Math.min(Math.max(convertNumber(val), min), max)
        setValue(result)
        onChange(result)
    }

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])
    return (
        <div className="slider-custom-wrap flex-col items-start" style={style}>
            <div className="title">
                {title}
            </div>
            <div className="slider-wrap items-center">
                <div className="slider-box">
                    <Slider
                        style={{
                            margin: 0,
                        }}
                        value={value}
                        max={max}
                        min={min}
                        step={step}
                        onChange={(e) => {
                            handleChangeNum(e)
                        }}
                    />
                </div>
                <Input
                    suffix={suffix}
                    size="small"
                    style={{
                        minWidth: 51,
                        maxWidth: 64,
                    }}
                    step={step}
                    value={value}
                    onChange={(e) => {
                        handleChangeNum(e.target.value)
                    }}
                />
            </div>

        </div>
    )
}
import Comp, { SubTemplate1, SubTemplate2 } from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '模版', // 组件的名称
    render: Comp, // 要展示在画布中的UI
    board: Desc, // 要展示在配置项中的UI
    preview: Preview, // 要展示在预览页面中的UI
    subPlugins: [], // 子组件，子组件可以理解为一个组件的内部组件，除了没有name和type外，其余的配置和父组件一致
    type: 'template-test', // 类型，也相当于组件的唯一标识，不要和其他组件的type重名
    props: {
        name: '我是父组件',
        imageUrl: 'https://th.bing.com/th?id=OIP.a13xv-Dkd4pE26B1PNFpyAHaGl&w=265&h=235&c=8&rs=1&qlt=90&o=6&dpr=2&pid=3.1&rm=2',
        subProps: [{
            name: '我是子组件1',
            age: 1,
        }, {
            name: '我是子组件2',
            age: 2
        }] // subProps, 子组件配置项，有几个子组件就在这里添加几个配置项，他们按顺序一一对应
    }, // 组件的配置参数, 在这里填写的数值为该组件配置参数的初始值，理论上可以存放任何数据，需要根据产品的规划来确定要在这里放什么数据
    extension: {}, // 描述，一般用来规定props参数的范围，是一个可选项，不会被用到实际项目中
    rectOptions: {
        width: 375, // 组件的宽度
        height: 142, // 组件的高度
        left: 0, // 组件距离画布的上边距
        top: 0, // 组件距离画布的左边距
        rotate: 0, // 组件的旋转角度
        subRectOptions: [{
            width: 200, 
            height: 100,
            left: 0,
            top: 100, 
            rotate: 0, 
        }, {
            width: 375, 
            height: 142,
            left: 0,
            top: 200, 
            rotate: 0, 
        }] // 子组件配置, 与props中的子元素配置相同，也是按顺序一一对应的关系。
    }, // 组件的尺寸，位置，角度配置，由于组件在画布可以被拖拽所以尺寸和位置单独成一个大配置项
    drawOptions: {
        resize: true, // 是否可以放大缩小
        drag: true, // 是否能拖拽
        rotate: false, // 是否能旋转
        keepRatio: false, // 是否等比例缩放
        renderDirections: ["nw","n","ne","w","e","sw","s","se"], // 元素可以被拖拽的点["nw","n","ne","w","e","sw","s","se"]，该参数可选，不添加的时候默认八个方向都可以拖拽
        subOptions: [{
            resize: true,
            drag: true,
            rotate: false,
            keepRatio: false,
            renderDirections: ["nw","n","ne","w","e","sw","s","se"],
        }, {
            resize: true,
            drag: true,
            rotate: false,
            keepRatio: false,
            renderDirections: ["nw","n","ne","w","e","sw","s","se"],
        }] // 子组件在画布中的设置，同上
    }, // 组件在画布中的设置，用来决定该组件在画布中的表现
}

export default Plugin
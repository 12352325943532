const isDev = false
const devHost = 'http://192.168.10.165:7097'
const releaseHost = 'https://draw-server.tgbcanvas.com'
const host = isDev ? devHost : releaseHost

// 画布页面!!!

const tokenPrefix = 'KLGg7wTkNF1VF1jRfb45'

export {
    host,
    tokenPrefix,
}
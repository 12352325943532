import React, { useState } from 'react'
import { Space } from 'antd'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import '@/plugins/desc.less'
import {
    Input,
    Slider,
    ConfigTitle,
    Select,
    BaseButton,
} from '@/components'
import { InputNumber3x, Slider3x, InputWithImg } from '@/HOC'


export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
    deleteInsertPlugin,
}) {
    const { props, rectOptions, extension } = currentPlugin
    const [currentIndex, setCurrentIndex] = useState(0)
    const curBanner = props.banners[currentIndex]
    return (
        <div
            className="desc-wrap"
        >
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="手风琴"
                detail="图片可向左或向右滑动切换，支持自动播放"
            />
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="轮播素材设置"
                        detail="设置轮播图片及跳转链接"
                        style={{
                            height: 37,
                        }}
                        titleSize={14}
                        detailSize={12}
                    />
                    <Select
                        chooseList={props.banners.map((v, i) => `第${i + 1}组`)}
                        onClick={(idx) => {
                            setCurrentIndex(idx)
                        }}
                        currentIndex={currentIndex}
                    />
                    <InputWithImg
                        labelContent="图片链接"
                        defaultValue={curBanner.imageUrl}
                        onChange={(e) => {
                            const list = [...props.banners]
                            list[currentIndex].imageUrl = e
                            updateCurrentPluginProps({
                                banners: list,
                            })
                        }}
                    />
                    <Input
                        labelContent="跳转链接"
                        defaultValue={curBanner.jumpUrl}
                        onChange={(e) => {
                            const list = [...props.banners]
                            list[currentIndex].jumpUrl = e
                            updateCurrentPluginProps({
                                banners: list,
                            })
                        }}
                    />
                    <Space>
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<DeleteFilled />}
                            content="删除本组"
                            onClick={() => {
                                const list = [...props.banners]
                                list.splice(currentIndex, 1)
                                if (list.length === 0) {
                                    deleteInsertPlugin(currentPlugin.insertId)
                                } else {
                                    setCurrentIndex(0)
                                    updateCurrentPluginProps({
                                        banners: list,
                                    })
                                }
                            }}
                        />
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<PlusOutlined />}
                            content="添加帧数"
                            onClick={() => {
                                const list = [...props.banners]
                                list.push(Object.assign({}, extension.struct))
                                updateCurrentPluginProps({
                                    banners: list
                                })
                            }}
                        />
                    </Space>

                </Space>
            </div>
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                    align="start"
                >
                    <ConfigTitle
                        title="轮播设置"
                        style={{
                            height: 21,
                        }}
                        titleSize={14}
                        detailSize={0}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                updateCurrentPluginProps({
                                    bannerWidth: val
                                })
                            }}
                            defaultValue={props.bannerWidth}
                        />
                        <InputNumber3x
                            labelContent="高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                changeCurrentPluginRect({
                                    height: val
                                })
                            }}
                            defaultValue={rectOptions.height}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.top = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.left = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.left}
                        />
                    </Space>
                    <InputNumber3x
                        labelContent="间距"
                        size="middle"
                        style={{
                            color: '#696969',
                        }}
                        onChange={(val) => {
                            updateCurrentPluginProps({
                                between: val
                            })
                        }}
                        defaultValue={props.between}
                    />
                    <Slider3x
                        title="滑动宽度"
                        suffix="px"
                        defaultValue={rectOptions.width}
                        max={extension?.scrollWidthDuration[1]}
                        min={extension?.scrollWidthDuration[0]}
                        onChange={(v) => {
                            changeCurrentPluginRect({
                                width: v,
                            })
                        }}
                    />
                    <Slider
                        title="自动切换时间"
                        suffix="秒"
                        defaultValue={props.interval}
                        max={extension?.intervalDuration[1]}
                        min={extension?.intervalDuration[0]}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                interval: v,
                            })
                        }}
                    />
                </Space>

            </div>
        </div>
    )
}
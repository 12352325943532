import Comp from './comp'
import Preview from './preview'
import Desc from './desc'

const Plugin = {
    name: '切换按钮',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [],
    type: 'change-btn',
    id: 'id10086', // 测试
    props: {
        imageUrl: '//img11.360buyimg.com/zx/jfs/t1/90881/20/42263/109402/64cb4fcaF5270517b/39e9a0bd5db74f8c.jpg',
    },
    extension: {},
    rectOptions: {
        width: 375,
        height: 480,
        left: 0,
        top: 0,
        rotate: 0,
        subRectOptions: []
    },
    drawOptions: {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
        subOptions: []
    },
}

export default Plugin
import React from 'react'
import { Space } from 'antd'
import '../../desc.less'
import {
    Input,
    Slider,
    ConfigTitle,
} from '@/components'
import { InputNumber3x, InputWithImg } from '@/HOC'

export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
}) {
    const { props, rectOptions, extension } = currentPlugin
    return (
        <div
            className="desc-wrap"
        >
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="悬浮ICON"
                detail="支持图片或动图链接"
            />
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={16}
                >
                    <InputWithImg
                        labelContent="图片/动图链接"
                        value={props.imageUrl}
                        defaultValue={props.imageUrl}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                imageUrl: e,
                            })
                        }}
                    />
                    <Input
                        labelContent="跳转链接"
                        value={props.jumpUrl}
                        defaultValue={props.jumpUrl}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                jumpUrl: e,
                            })
                        }}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.width = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.width}
                        />
                        <InputNumber3x
                            labelContent="高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.height = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.height}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.top = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.left = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.left}
                        />
                    </Space>
                    <Slider
                        suffix="秒"
                        title="动画时间"
                        max={extension?.animateTimeDuration[1]}
                        min={extension?.animateTimeDuration[0]}
                        defaultValue={props.animateTime}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                animateTime: e
                            })
                        }}
                    />
                    <Slider
                        suffix="度"
                        max={extension?.animateRangeDuration[1]}
                        min={extension?.animateRangeDuration[0]}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                animateRange: e
                            })
                        }}
                        defaultValue={props.animateRange}
                    />
                </Space>
            </div>
        </div>
    )
}
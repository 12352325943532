import React from 'react'
import { Space } from 'antd'
import '@/plugins/desc.less'
import {
    Input,
    Slider,
    ConfigTitle,
    ColorPicker,
    Radio,
} from '@/components'
import { InputNumber3x, InputWithImg } from '@/HOC'

export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
}) {
    const { props, rectOptions, extension } = currentPlugin
    const subRectOptions1 = rectOptions.subRectOptions[0]
    const subRectOptions2 = rectOptions.subRectOptions[1]
    const subRectOptions3 = rectOptions.subRectOptions[2]
    const subProps1 = props.subProps[0]
    const subProps2 = props.subProps[1]
    const subProps3 = props.subProps[2]

    return (
        <div className="desc-wrap">
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="弹窗组件"
                detail="仅在首次滑到该楼层时弹出一次弹窗图片"
            />
            <div className="desc-board">
                <Space direction="vertical" size={16}>
                    <ConfigTitle
                        title="弹窗设置"
                        style={{
                            height: 21,
                        }}
                        titleSize={14}
                        detailSize={0}
                    />
                    <InputWithImg
                        labelContent="图片/视频链接"
                        defaultValue={props.imageUrl}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                imageUrl: e,
                            })
                        }}
                    />
                    <Input
                        labelContent="跳转链接"
                        defaultValue={props.jumpUrl}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                jumpUrl: e,
                            })
                        }}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.width = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.width}
                        />
                        <InputNumber3x
                            labelContent="高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.height = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.height}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.top = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.left = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.left}
                        />
                    </Space>
                    <Slider
                        suffix="秒"
                        title="动画时间"
                        max={extension.animateTimeDuration[1]}
                        min={extension.animateTimeDuration[0]}
                        defaultValue={props.animateTime}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                animateTime: v
                            })
                        }}
                    />
                    <Slider
                        suffix="秒"
                        title="展示时间"
                        max={extension.showTimeDuration[1]}
                        min={extension.showTimeDuration[0]}
                        defaultValue={props.showTime}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                showTime: v
                            })
                        }}
                    />
                </Space>
            </div>
            <div className="desc-board">
                <Space direction="vertical" size={16}>
                    <ConfigTitle
                        title="缩放设置"
                        style={{
                            height: 21,
                        }}
                        titleSize={14}
                        detailSize={0}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="缩放后宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                subRectOptions3.width = val
                                changeCurrentPluginRect(subRectOptions3, 3)
                            }}
                            defaultValue={subRectOptions3.width}
                        />
                        <InputNumber3x
                            labelContent="缩放后高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                subRectOptions3.height = val
                                changeCurrentPluginRect(subRectOptions3, 3)
                            }}
                            defaultValue={subRectOptions3.height}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                subRectOptions3.top = val
                                changeCurrentPluginRect(subRectOptions3, 3)
                            }}
                            defaultValue={subRectOptions3.top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                subRectOptions3.left = val
                                changeCurrentPluginRect(subRectOptions3, 3)
                            }}
                            defaultValue={subRectOptions3.left}
                        />
                    </Space>
                    <Slider
                        suffix="秒"
                        title="缩放动画时间"
                        max={extension.scaleAnimateTimeDuration[1]}
                        min={extension.scaleAnimateTimeDuration[0]}
                        defaultValue={subProps3.scaleAnimateTime}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                scaleAnimateTime: v
                            }, 3)
                        }}
                    />
                    <Slider
                        suffix="度数"
                        title="摆动幅度"
                        max={extension.animateRotateDuration[1]}
                        min={extension.animateRotateDuration[0]}
                        defaultValue={subProps3.animateRotate}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                animateRotate: v
                            }, 3)
                        }}
                    />
                    <Slider
                        suffix="秒"
                        title="摆动频率"
                        max={extension.animateRateDuration[1]}
                        min={extension.animateRateDuration[0]}
                        defaultValue={subProps3.animateRate}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                animateRate: v
                            }, 3)
                        }}
                    />
                </Space>
            </div>
            <div className="desc-board">
                <Space direction="vertical" size={16}>
                    <ConfigTitle
                        title="关闭按钮设置"
                        style={{
                            height: 21,
                        }}
                        titleSize={14}
                        detailSize={0}
                    />
                    <InputWithImg
                        labelContent="图片链接"
                        defaultValue={subProps1.closeImgUrl}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                closeImgUrl: e,
                            }, 1)
                        }}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                subRectOptions1.width = val
                                changeCurrentPluginRect(subRectOptions1, 1)
                            }}
                            defaultValue={subRectOptions1.width}
                        />
                        <InputNumber3x
                            labelContent="高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                subRectOptions1.height = val
                                changeCurrentPluginRect(subRectOptions1, 1)
                            }}
                            defaultValue={subRectOptions1.height}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                subRectOptions1.top = val
                                changeCurrentPluginRect(subRectOptions1, 1)
                            }}
                            defaultValue={subRectOptions1.top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                subRectOptions1.top = val
                                changeCurrentPluginRect(subRectOptions1, 1)
                            }}
                            defaultValue={subRectOptions1.left}
                        />
                    </Space>        
                </Space>
            </div>
            <div className="desc-board">
                <Space direction="vertical" size={16}>
                    <ConfigTitle
                        title="倒计时文字设置"
                        style={{
                            height: 21,
                        }}
                        titleSize={14}
                        detailSize={0}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                subRectOptions2.top = val
                                changeCurrentPluginRect(subRectOptions2, 2)
                            }}
                            defaultValue={subRectOptions2.top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                subRectOptions2.left = val
                                changeCurrentPluginRect(subRectOptions2, 2)
                            }}
                            defaultValue={subRectOptions2.left}
                        />
                    </Space>
                    <Slider
                        suffix="px"
                        title="文字大小"
                        max={extension.fontSizeDuration[1]}
                        min={extension.fontSizeDuration[0]}
                        defaultValue={subProps2.fontSize}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                fontSize: v
                            }, 2)
                        }}
                    />
                    <Slider
                        suffix=""
                        title="文字粗细"
                        step={100}
                        max={extension.fontWeightDuration[1]}
                        min={extension.fontWeightDuration[0]}
                        defaultValue={subProps2.fontWeight}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                fontWeight: v
                            }, 2)
                        }}
                    />
                    <ColorPicker
                        title="文字颜色"
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                color: v
                            }, 2)
                        }}
                        defaultValue={subProps2.color}
                    />
                </Space>
            </div>
            
        </div>
    )
}
import React from 'react'
import schema from './schema.json'
import * as pluginUIComp from '../_component/drawComponent'

const { draw_ui, data } = schema
if (Object.keys(data).length === 1) {

}

// TODO 暂时没考虑子组件
const drawUIData = draw_ui[0]
const origin = data.main_data

// const rectOptions = parseSchemaRectOrProerties(origin.rect)
// const props = parseSchemaRectOrProerties(origin.properties)

// let renderProps = props[drawUIData.origin_data.name]

const render = {
    Comp: pluginUIComp[drawUIData.type],
    props: {}
}

function Comp({
    rectOptions,
    props,
}) {
    const { width, height, left, top } = rectOptions

    // TODO 封装起来
    if (drawUIData.type === 'ImgGroup') {
        const imageList = props[drawUIData.origin_data.key].map((item, index) => {
            const result = {}
            Object.keys(drawUIData.props.imageList[0]).forEach((key, i) => {
                const propsKey = drawUIData.props.imageList[0][key]
                result[key] = item[propsKey]
            })
            return result
        })
        render.props = {
            imageList
        }
    } else {
        // const result = {}
        // Object.keys(drawUIData.props).forEach((key, i) => {
        //     const propsKey = drawUIData.props[key]
        //     result[key] = renderProps[propsKey]
        // })
        // renderProps = result
    }

    return <div
        style={{
            position: 'absolute',
            transform: `translate(${left}px, ${top}px)`,
            width: !isNaN(Number(width)) ? Number(width) : 'auto',
            height: !isNaN(Number(height)) ? Number(height) : 'auto',
        }}
    >
        <render.Comp
            {...render.props}
        ></render.Comp>
    </div>
}



export default Comp
import { useRefreshMediaRect } from '@/utils/hooks'
const LeftArrow = ({ rectOptions, props }) => {
    const { left, top, width, height } = rectOptions.subRectOptions[0]
    const { imageUrl } = props.subProps[0]
    useRefreshMediaRect(imageUrl, rectOptions.subRectOptions[0], 1)
    return (
        <div
            style={{
                position: 'absolute',
                width,
                height,
                transform: `translate(${left}px, ${top}px)`,
            }}
        >
            <img style={{ width: '100%', height: '100%' }} src={imageUrl} alt="" />
        </div>
    )
}

const RightArrow = ({ rectOptions, props }) => {
    const { left, top, width, height } = rectOptions.subRectOptions[1]
    const { imageUrl } = props.subProps[1]
    useRefreshMediaRect(imageUrl, rectOptions.subRectOptions[1], 2)
    return (
        <div
            style={{
                position: 'absolute',
                width,
                height,
                transform: `translate(${left}px, ${top}px)`,
            }}
        >
            <img style={{ width: '100%', height: '100%' }} src={imageUrl} alt="" />
        </div>
    )
}

export {
    LeftArrow,
    RightArrow,
}
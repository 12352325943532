import React, { useEffect, useRef } from 'react'

export default function Preview({ baseConfig, rectOptions, props, zIndex = 0 }) {
    const textRef = useRef(null)
    const {  width, height, left, top  } = rectOptions
    const { fontSize, fontWeight, color, speed, text } = props
    const trigger = () => {
        const textWidth = textRef.current.getBoundingClientRect().width
        textRef.current.style.transition = `${speed}s linear`
        textRef.current.style.transform = `translateX(-${width + textWidth}px)`
    }

    const reset = () => {
        textRef.current.style.transition = '0s'
        textRef.current.style.transform = 'translateX(0px)'
    }
    useEffect(() => {
        trigger()
    }, [])
    return (
        <div style={{
            width,
            height,
            position: 'absolute',
            left,
            top,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            color,
            fontSize,
            fontWeight,
            zIndex,
        }}>
            <span
                style={{
                    position: 'absolute',
                    left: width,
                    whiteSpace: 'nowrap',
                }}
                onTransitionEnd={() => {
                    reset()
                    trigger()
                }}
                ref={textRef}
            >
                {text}
            </span>
        </div>
    )
}
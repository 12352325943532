import React from 'react'
import './index.less'
import { useRefreshMediaRect } from '@/utils/hooks'

export default function Comp({ rectOptions, props }) {
    const { width, height, left, top } = rectOptions
    const { videoUrl, posterUrl, maskUrl, radius, muted } = props
    useRefreshMediaRect(videoUrl)
    return (
        <div className="video-comp-wrap"
            style={{
                width,
                height,
                transform: `translate(${left}px, ${top}px)`,
                overflow: 'hidden',
            }}
        >
            <video
                style={{
                    borderRadius: radius,
                }}
                className="video-content"
                src={videoUrl}
                poster={posterUrl}
                autoPlay
                muted={muted}
                loop
            ></video>
            <img className="mask-content" src={maskUrl} alt="" />
        </div>
    )
}

export const MutedBtn = ({ rectOptions, props }) => {
    const { muted, subProps } = props
    const { mutedImgUrl, unmutedImgUrl } = subProps[0]
    const { width, left, top, height } = rectOptions.subRectOptions[0]
    return (
        <div
            style={{
                position: 'absolute',
                width,
                height,
                transform: `translate(${left}px, ${top}px)`,
            }}
        >
            <img
                src={muted ? mutedImgUrl : unmutedImgUrl}
                alt=""
                style={{
                    width: '100%',
                    height: '100%',
                }}
            />
        </div>
    )
}
import React, { useState, useEffect, useRef } from 'react'
import { InputNumber } from '@/components'

export default function InputNumberHOC(props) {
    const [value, setValue] = useState(props.defaultValue)
    const isFocus = useRef(false)
    useEffect(() => {
        // if (isFocus.current === false) {
        //     setValue(props.defaultValue * 3)
        // }
        setValue(props.defaultValue * 3)
    }, [props.defaultValue])
    return (
        <InputNumber
            {...props}
            defaultValue={value}
            onChange={(v) => {
                props.onChange(v / 3)
            }}
            onFocus={() => {
                isFocus.current = true
            }}
            onBlur={() => {
                isFocus.current = false
            }}
        />
    )
}
import React, { Fragment, useState } from 'react'
import { Space } from 'antd'
import { ORIGIN_DATA_TYPE } from '@/consts/plugin'
import { getConfigDataType } from '@/plugins/schemaUtils'

import {
    Input, // 文本输入框 ---- 文本填写
    ConfigTitle, // 标题，可用作大标题和小标题
    InputNumber, // 数字输入框 ---- 数字填写（涉及尺寸的时候）
    Slider, // 滑动条 ---- 滑动游尺
    Radio, // 单选选择器 ---- 单选框
    Select, // select Tab 选择器 ---- 涉及到轮播等添加帧数的组件时候用到（具体使用参考HotBanner组件)
    BaseButton, // 按钮 ---- 按钮 具体用法参考AccordionBanner 第79-98
    ColorPicker, // 颜色选择器 ---- 颜色选择
    TextArea, // 多行文本输入框
} from '@/components'
import {
    HotArea, // 热区 ---- 热区（具体使用参考HotBanner组件)
    InputNumber3x, // 3倍数字输入框 ---- 数字填写（涉及尺寸的时候）
    Slider3x, // 3倍数字滑动条 ---- 滑动游尺（涉及尺寸的时候）
    InputWithImg, // 输入框带图片或视频 ---- 媒体资源填写
} from '@/HOC'


const SpaceDesc = ({
    props,
    rectOptions,
    config,
    updateCurrentPluginProps,
    changeCurrentPluginRect,
    dataType = ORIGIN_DATA_TYPE.NULL,
    schema,
}) => {
    return (
        <Space size={10}>
            {
                config.children.map((child, index) => {
                    let renderData = {}
                    let renderMethod = () => {}
                    if (dataType === ORIGIN_DATA_TYPE.NULL) {
                        dataType = getConfigDataType(schema.data, child)
                    }
                    switch(dataType) {
                        case ORIGIN_DATA_TYPE.PROPERTIES:
                            renderData = props
                            renderMethod = updateCurrentPluginProps
                            break
                        case ORIGIN_DATA_TYPE.RECT:
                            renderData = rectOptions
                            renderMethod = changeCurrentPluginRect
                            break
                    }
                    return (
                        <Fragment key={index}>
                            {
                                child.type === 'InputWithImg' && <InputWithImg
                                    labelContent={
                                        child.props.labelContent
                                    }
                                    defaultValue={renderData[child.origin_data.key]}
                                    onChange={(val) => {
                                        renderMethod({
                                            [`${child.origin_data.key}`]: val
                                        })
                                    }}
                                />
                            }
                            {
                                child.type === 'Input' && <Input
                                    labelContent={
                                        child.props.labelContent
                                    }
                                    defaultValue={renderData[child.origin_data.key]}
                                    onChange={(val) => {
                                        renderMethod({
                                            [`${child.origin_data.key}`]: val
                                        })
                                    }}
                                />
                            }
                            {
                                child.type === 'InputNumber3x' && <InputNumber3x
                                    labelContent={
                                        child.props.labelContent
                                    }
                                    style={{
                                        color: '#696969',
                                    }}
                                    defaultValue={renderData[child.origin_data.key]}
                                    onChange={(val) => {
                                        renderMethod({
                                            [`${child.origin_data.key}`]: val
                                        })
                                    }}
                                />
                            }
                        </Fragment>
                    )
                })
            }
        </Space>
    )
}

export default SpaceDesc
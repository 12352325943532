import React, { useMemo } from 'react'
function Comp({
    rectOptions,
    props,
}) {
    const { width, height, left, top } = rectOptions
    const { navList, direction, space, navWidth, navHeight } = props
    return <div
        style={{
            position: 'absolute',
            transform: `translate(${left}px, ${top}px)`,
            display: 'flex',
            flexDirection: direction === '水平' ? 'row' : 'column'
        }}
    >
        {
            navList.map((nav, index) => {
                return <div
                    key={index}
                    style={{
                        width: Number(navWidth),
                        height: Number(navHeight),
                        backgroundColor: 'rgba(255, 0, 0, 0.3)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: direction !== '水平' || index === 0 ? 0 : Number(space),
                        marginTop: direction === '水平' || index === 0 ? 0 : Number(space),
                    }}
                >锚点{index + 1}</div>
            })
        }
    </div>
}



export default Comp
import React from 'react'
import { useRefreshMediaRect } from '@/utils/hooks'
import './index.less'

export default function Comp({ rectOptions, props }) {
    const { width, height, left, top } = rectOptions
    const { imageUrl } = props
    useRefreshMediaRect(imageUrl, rectOptions)
    return (
        <div className="icon-comp-wrap"
            style={{
                transform: `translate(${left}px, ${top}px)`,
                width,
                height,
            }}
        >
            <img src={imageUrl} alt="" />
        </div>
    )
}
import React, { useState, useEffect, useRef } from 'react'

export default function AlertCompPreview({
    baseConfig,
    props,
    rectOptions,
    zIndex = 0,
}) {
    const timer = useRef(null)
    const {
        width,
        height,
        left,
        top,
        subRectOptions,
    } = rectOptions
    const { mediaUrl, jumpUrl, animateTime, showTime, subProps, type } = props
    const subProp1 = subProps[0]
    const subProp2 = subProps[1]
    const rect1 = subRectOptions[0]
    const rect2 = subRectOptions[1]
    const defaultStyle = {
        opacity: type === 2 ? 0 : 1,
        transform: `translateY(${type === 1 ? -height - top : 0}px) scale(${type === 0 ? 0 : 1})`,
    }
    const activeStyle = {
        opacity: 1,
        transform: `translateY(0px) scale(1)`
    }
    const [wrapStyle, setWrapStyle] = useState(defaultStyle)
    const [countdown, setCountDown] = useState(animateTime + showTime)

    useEffect(() => {
        setWrapStyle(activeStyle)
        timer.current = setInterval(() => {
            setCountDown((state) => {
                if (state === 0) {
                    setWrapStyle(defaultStyle)
                    clearInterval(timer.current)
                    return 0
                } else {
                    return state - 1
                }
            })
        }, 1000)
        return () => {
            clearInterval(timer.current)
        }
    }, [])
    return (
        <div
            style={Object.assign({
                width,
                height,
                left,
                top,
                zIndex,
                opacity: type === 2 ? 0 : 1,
                transform: `translateY(${type === 1 ? -height - top : 0}px) scale(${type === 0 ? 0 : 1})`,
                position: 'absolute',
                transition: `${animateTime}s`
            }, wrapStyle)}
        >
            {
                mediaUrl.indexOf('.mp4') > -1 ? (
                    <video
                        src={mediaUrl}
                        style={{
                            width,
                            height,
                        }}
                        autoPlay
                        muted
                        onClick={() => {
                            window.open(jumpUrl)
                        }}
                    />
                ) : <img
                    style={{
                        width,
                        height,
                    }}
                    src={mediaUrl}
                    onClick={() => {
                        window.open(jumpUrl)
                    }}
                />
            }

            <img
                style={{
                    width: rect1.width,
                    height: rect1.height,
                    left: rect1.left - left,
                    top: rect1.top - top,
                    position: 'absolute',
                }}
                onClick={() => {
                    clearInterval(timer.current)
                    setWrapStyle(defaultStyle)
                }}
                src={subProp1.closeImgUrl}
                alt=""
            />
            <div
                style={{
                    position: 'absolute',
                    left: rect2.left - left,
                    top: rect2.top - top,
                    fontSize: subProp2.fontSize,
                    fontWeight: subProp2.fontWeight,
                    color: subProp2.color
                }}
            >
                {countdown}
            </div>
        </div>
    )
}
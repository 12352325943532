import React, { useMemo } from 'react'

function Comp({
    rectOptions,
    props,
}) {
    const { width, height, left, top } = rectOptions
    const { vegaScrollList, imageWidth, imageHeight, space } = props
    return <div
        style={{
            position: 'absolute',
            transform: `translate(${left}px, ${top}px)`,
            width: !isNaN(Number(width)) ? Number(width) : 'auto',
            height: !isNaN(Number(imageHeight)) ? Number(imageHeight) : 'auto',
        }}
    >
        <img style={{
            width: imageWidth,
            height: imageHeight,
        }} src={vegaScrollList[0].imageUrl} alt="" />
    </div>
}



export default Comp
import Comp from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '跑马灯',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [],
    type: 'marquee',
    props: {
        text: '请填写文本',
        speed: 3,
        fontSize: 16,
        fontWeight: 400,
        color: '#000'
    },
    extension: {
        speedDuration: [1, 20],
        fontSizeDuration: [0, 100],
        fontWeightDuration: [100, 900],
    },
    rectOptions: {
        width: 375,
        height: 30,
        left: 0,
        top: 0,
        rotate: 0,
        subRectOptions: []
    },
    drawOptions: {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: false,
        subOptions: []
    },
}

export default Plugin
import React, { useState } from 'react'

export default function VideoCompPreview({
    rectOptions,
    props,
    zIndex = 0,
}) {
    const { width, height, left, top, subRectOptions } = rectOptions
    const { videoUrl, posterUrl, maskUrl, jumpUrl, radius, muted, subProps } = props
    const subWidth = subRectOptions[0].width
    const subHeight = subRectOptions[0].height
    const subLeft = subRectOptions[0].left
    const subTop = subRectOptions[0].top
    const { mutedImgUrl, unmutedImgUrl } = subProps[0]
    const [isMuted, setIsMuted] = useState(muted)
    return (
        <>
            <div
                onClick={() => {
                    window.open(jumpUrl)
                }}
                style={{
                    position: 'absolute',
                    width,
                    height,
                    borderRadius: radius,
                    overflow: 'hidden',
                    left,
                    top,
                    zIndex,
                }}
            >
                <video
                    src={videoUrl}
                    autoPlay
                    style={{
                        width,
                        height,
                        objectFit: 'fill',
                    }}
                    loop
                    muted={isMuted}
                    poster={posterUrl}
                ></video>

                {
                    maskUrl !== '' && 
                    <img style={{
                        width,
                        height,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }} src={maskUrl} alt="" />
                }
                
            </div>
            <img
                style={{
                    width: subWidth,
                    height: subHeight,
                    position: 'absolute',
                    top: subTop,
                    left: subLeft,
                    zIndex,
                }}
                onClick={() => {
                    setIsMuted(!isMuted)
                }}
                src={isMuted ? mutedImgUrl : unmutedImgUrl}
            />
        </>
        
    )
}
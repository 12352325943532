import React from 'react'

export default function CountDownPreview({
    props,
    rectOptions,
    showEndBgImgUrl,
    zIndex = 0,
}) {
    const {
        width,
        height,
        left,
        top,
    } = rectOptions 
    const { startBgImgUrl, endBgImgUrl, jumpUrl } = props
    return (
        <img
            style={{
                width,
                height,
                position: 'absolute',
                left,
                top,
                zIndex,
            }}
            onClick={
                window.open(jumpUrl)
            }
            src={showEndBgImgUrl ? endBgImgUrl : startBgImgUrl}
            alt=""
        />
    )
}
import React from 'react'
import _ from 'lodash'

export default React.memo(function ImgGroup({
    imageList = [{
        imageUrl: '',
        imageWidth: 0,
        imageHeight: 0,
    }],
    space = 0,
    direction = '水平',
    style = {}
}) {
    return <div
        style={Object.assign({
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: direction === '水平' ? 'row' : 'column'
        }, style)}
    >
        {
            imageList.map(({ imageWidth, imageHeight, imageUrl }, i) => <img
                key={i}
                style={{
                    flexShrink: 0,
                    width: Number(imageWidth),
                    height: Number(imageHeight),
                    marginLeft: direction !== '水平' || i === 0 ? 0 : Number(space),
                    marginTop: direction === '水平' || i === 0 ? 0 : Number(space)
                }}
                src={imageUrl}
            />)
        }
    </div>
})
import React, { useState } from 'react'
import { Space } from 'antd'
import { HotArea } from '@/HOC'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import '../../desc.less'
import {
    Slider,
    ConfigTitle,
    Radio,
    Select,
    BaseButton,
} from '@/components'
import { InputNumber3x, InputWithImg } from '@/HOC'


export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
    deleteInsertPlugin,
}) {
    const { props, rectOptions, extension } = currentPlugin
    const [currentIndex, setCurrentIndex] = useState(0)
    const curBanner = props.banners[currentIndex]
    return (
        <div
            className="desc-wrap"
        >
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="热区轮播"
                detail="轮播图支持画热区设置跳转链接，也可显示价格"
            />
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="轮播帧素材设置"
                        detail="每张图片可单独编辑热区"
                        style={{
                            height: 37,
                        }}
                        titleSize={14}
                        detailSize={12}
                    />
                    <Select
                        chooseList={props.banners.map((v, i) => `第${i + 1}组`)}
                        onClick={(idx) => {
                            setCurrentIndex(idx)
                        }}
                        currentIndex={currentIndex}
                    />
                    <InputWithImg
                        labelContent="图片链接"
                        defaultValue={curBanner.imageUrl}
                        onChange={(e) => {
                            const list = [...props.banners]
                            list[currentIndex].imageUrl = e
                            updateCurrentPluginProps({
                                banners: list,
                            })
                        }}
                    />
                    <HotArea
                        editContentSize={{
                            width: rectOptions.width,
                            height: rectOptions.height,
                        }}
                        defaultValue={curBanner.hotArea}
                        editContent={curBanner.imageUrl}
                        onSave={(data) => {
                            const list = [...props.banners]
                            list[currentIndex].hotArea = data
                            updateCurrentPluginProps({
                                banners: list,
                            })
                        }}
                    />
                    <Space>
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<DeleteFilled />}
                            content="删除本组"
                            onClick={() => {
                                const list = [...props.banners]
                                list.splice(currentIndex, 1)
                                if (list.length === 0) {
                                    deleteInsertPlugin(currentPlugin.insertId)
                                } else {
                                    setCurrentIndex(0)
                                    updateCurrentPluginProps({
                                        banners: list,
                                    })
                                }
                            }}
                        />
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<PlusOutlined />}
                            content="添加帧数"
                            onClick={() => {
                                const list = [...props.banners]
                                list.push(Object.assign({}, extension.struct))
                                updateCurrentPluginProps({
                                    banners: list
                                })
                            }}
                        />
                    </Space>

                </Space>
            </div>
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="轮播设置"
                        style={{
                            height: 21,
                        }}
                        titleSize={14}
                        detailSize={0}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.width = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.width}
                        />
                        <InputNumber3x
                            labelContent="高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.height = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.height}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.top = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.left = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.left}
                        />
                    </Space>
                    <Slider
                        suffix="秒"
                        title="切换间隔:"
                        defaultValue={props.duration}
                        max={extension?.durationSection[1]}
                        min={extension?.durationSection[0]}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                duration: v
                            })
                        }}
                    />
                    <Radio
                        title="切换方向:"
                        defaultValue={props.direction}
                        radioList={['水平', '垂直']}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                direction: v
                            })
                        }}
                    />
                </Space>

            </div>
        </div>
    )
}
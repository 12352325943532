import React, { useState, useEffect } from 'react'

export default function FloatIconPreview({
    baseConfig,
    props,
    rectOptions,
    zIndex = 0,
}) {
    const {
        width,
        height,
        left,
        top,
    } = rectOptions
    const { imageUrl, jumpUrl, animateTime, animateRange } = props
    const [rotate, setRotate] = useState(0)
    useEffect(() => {
        setRotate(animateRange)
    }, [])
    return (
        <img
            style={{
                width,
                height,
                position: 'absolute',
                left,
                top,
                zIndex,
                transform: `rotate(${rotate}deg)`,
                transition: `transform ${animateTime}s linear`
            }}
            onTransitionEnd={() => {
                setRotate(-rotate)
            }}
            onClick={() => {
                window.location.href = jumpUrl
            }}
            src={imageUrl}
            alt=""
        />
    )
}
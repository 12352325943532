import React, { useRef, useCallback, useEffect } from 'react'
import CardSlider from '../../components/CardSlider'
export default function Preview({
    baseConfig,
    rectOptions,
    props,
    zIndex = 0,
}) {
    const maxWidth = baseConfig.width
    const maxHeight = baseConfig.height
    const { width, height, left, top } = rectOptions
    const { bannerWidth, between, interval, banners, flyImageList } = props
    console.log(flyImageList, 'flyImageList===========000');
    // let newList =[]
    // for(let i=1;i<10;i++){
    //     let imageUrl= flyImageList[0][`imageUrl${i}`]
    //     let flyDirection= flyImageList[0][`flyDirection${i}`]
    //     let flyDistance= flyImageList[0][`flyDistance${i}`]
    //     let flyTime= flyImageList[0][`flyTime${i}`]
    //     if(imageUrl && flyDirection && flyDistance && flyTime){
    //         newList.push({
    //             imageUrl,
    //             flyDirection,
    //             flyDistance,
    //             flyTime
    //         })
    //     }
    // }
    // console.log(newList);
    const timer = useRef(null)
    const stopAutoScroll = useCallback(() => {
        clearInterval(timer.current)
    }, [timer])
    useEffect(() => {
        return stopAutoScroll
    }, [])

    return (
        <div
            style={{
                position: 'absolute',
                width,
                height,
                top: 0,
                left: 0,
                zIndex,
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    width,
                    height,
                    left,
                    top,
                    overflow: 'hidden',
                }}
            >
                <CardSlider
                    banners={flyImageList}
                    wrapWidth={width}
                    between={between}
                    interval={interval}
                    bannerRect={{
                        width: bannerWidth,
                        height: height
                    }}
                />

            </div>
        </div>
    )
}
import { DD, HH, MM, SS } from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '倒计时',
    render: () => <></>,
    board: Desc,
    preview: Preview,
    subPlugins: [DD, HH, MM, SS],
    type: 'count-down',
    props: {
        startTime: Date.now(),
        endTime: Date.now() + 24 * 60 * 60 * 1000,
        subProps: [
            {
                show: true,
                fontSize: 18,
                fontWeight: 600,
                color: '#FFFFFF',
            },
            {
                show: true,
                fontSize: 18,
                fontWeight: 600,
                color: '#FFFFFF',
            },
            {
                show: true,
                fontSize: 18,
                fontWeight: 600,
                color: '#FFFFFF',
            },
            {
                show: true,
                fontSize: 18,
                fontWeight: 600,
                color: '#FFFFFF',
            },
        ]
    },
    extension: {
        fontSizeDuration: [0, 200],
        fontWeightDuration: [100, 900],
    },
    rectOptions: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
        rotate: 0,
        subRectOptions: [{
            left: 114,
            top: 58,
            rotate: 0,
        }, {
            left: 154,
            top: 58,
            rotate: 0,
        }, {
            left: 194,
            top: 58,
            rotate: 0,
        }, {
            left: 234,
            top: 58,
            rotate: 0,
        }]
    },
    drawOptions: {
        resize: false,
        drag: false,
        rotate: false,
        keepRatio: false,
        subOptions: [{
            resize: false,
            drag: true,
            rotate: false,
            keepRatio: false,
        }, {
            resize: false,
            drag: true,
            rotate: false,
            keepRatio: false,
        }, {
            resize: false,
            drag: true,
            rotate: false,
            keepRatio: false,
        }, {
            resize: false,
            drag: true,
            rotate: false,
            keepRatio: false,
        }]
    },
}

export default Plugin
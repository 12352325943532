import React, { useState } from 'react'

export default function Comp ({ rectOptions, props }) {
    const { left, top } = rectOptions
    const { scrollItemWidth, scrollItemHeight, scrollWrapWidth, between, scrollList } = props
    // const { navList, between, direction, navWidth, navHeight } = props
    return (
        <div
            className="nav-comp-wrap"
            style={{
                position: 'absolute',
                width: scrollWrapWidth,
                height: scrollItemHeight,
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'flex-start',
                transform: `translate(${left}px, ${top}px)`,
            }}
        >
            {
                scrollList.map((item, index) => {
                    return (
                        <img
                            style={{
                                flexShrink: 0,
                                width: scrollItemWidth,
                                height: scrollItemHeight,
                                marginLeft: index === 0 ? 0 : between,
                            }}
                            src={item.imageUrl}
                            alt=""
                        />
                    )
                })
            }
        </div>
    )
}
import Comp, { CloseBtn, CountDown } from './comp'
import Preview from './preview'
import Desc from './desc'

const Plugin = {
    name: '弹窗组件',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [CloseBtn, CountDown],
    type: 'alertComp',
    id: 'id4', // 测试
    props: {
        mediaUrl: '//img11.360buyimg.com/zx/jfs/t1/154053/28/3631/358762/5f97abbaE29bd2ee8/ca99e56fd3cf4e4b.png',
        jumpUrl: '',
        type: 2, // '中心弹出', '上方滑入', '渐现'
        animateTime: 1,
        showTime: 3,
        subProps: [{
            closeImgUrl: '//img30.360buyimg.com/zx/jfs/t1/123463/4/3969/748/5ed88322Ed2f81b91/1dc1908ada1eb0b7.png',
        }, {
            // time: 1, // animateTime + showTime
            fontSize: 32,
            fontWeight: 400,
            color: '#ffffff',
        }]
    },
    extension: {
        animateTimeDuration: [1, 10],
        showTimeDuration: [1, 10],
        fontSizeDuration: [0, 200],
        fontWeightDuration: [100, 900],
    },
    rectOptions: {
        width: 375,
        height: 475,
        left: 0,
        top: 0,
        rotate: 0,
        subRectOptions: [
            {
                width: 50,
                height: 50,
                left: 312,
                top: 15,
                rotate: 0,
            },
            {
                left: 6,
                top: 16,
                rotate: 0,
            }
        ]
    },
    drawOptions: {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
        subOptions: [{
            resize: true,
            drag: true,
            rotate: false,
            keepRatio: true,
        }, {
            resize: false,
            drag: true,
            rotate: false,
            keepRatio: false,
        }]
    },
}

export default Plugin
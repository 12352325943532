import Comp, { ScrollDot } from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '滑屏视频',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [],
    type: 'scroll-video',
    id: 'id11', // 测试
    props: {
        scrollList: [
            {
                mediaUrl: '//img14.360buyimg.com/zx/jfs/t1/126971/17/17224/388817/5f9fcdefE488b3e92/041ef907bbe86c7b.jpg',
                jumpUrl: '',
                maskUrl: '',
                poster: '',
                hotArea: [],
            },
            {
                mediaUrl: 'https://jvod.300hu.com/vod/product/e6c1cc3b-c948-4e6c-881c-11cd7add4e52/c01fed09c99a49f2ad5b5d7e4deeced0.mp4?source=2&h265=h265/18799/29eadccdd2204c8b92fc73fec387f7ae.mp4',
                jumpUrl: '',
                maskUrl: '',
                poster: '',
                hotArea: [],
            }
        ],
        between: 0,
        scrollItemWidth: 375,
        scrollItemHeight: 370,
        scrollWrapWidth: 375,
        subProps: [
            {
                showNumber: 0,
                defaultWidth: 30,
                activeWidth: 50,
                between: 5,
                radius: 4,
                defaultColor: '#000000',
                activeColor: '#ffffff',
            }
        ],
    },
    extension: {
        scrollWidthDuration: [0, 1125],
        speedDuration: [1, 7],
        struct: {
            mediaUrl: 'https://jvod.300hu.com/vod/product/e6c1cc3b-c948-4e6c-881c-11cd7add4e52/c01fed09c99a49f2ad5b5d7e4deeced0.mp4?source=2&h265=h265/18799/29eadccdd2204c8b92fc73fec387f7ae.mp4',
            jumpUrl: '',
            maskUrl: '',
            poster: '',
            hotArea: [],
        }
    },
    rectOptions: {
        width: 375,
        height: 370,
        left: 0,
        top: 30,
        rotate: 0,
        subRectOptions: [
            {
                width: 200,
                height: 10,
                left: 120,
                top: 460,
                rotate: 0,
            },
        ]
    },
    drawOptions: {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: false,
        renderDirections: ['e'],
        subOptions: [
            {
                resize: false,
                drag: true,
                rotate: false,
                keepRatio: false,
                // renderDirections: ['n', 's']
            }
        ],
    },
}

export default Plugin
import React, { useState } from 'react'
import { Space } from 'antd'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import Comp from './comp'
import '@/plugins/desc.less'
import {
    Input,
    Slider,
    ConfigTitle,
    Select,
    BaseButton,
    Radio,
} from '@/components'
import { InputNumber3x, Slider3x, InputWithImg } from '@/HOC'
import { HotArea } from '../../HOC'

export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
    addSubPlugins,
    deleteInsertPlugin,
    deleteSubPlugins,
}) {
    const { props, rectOptions, extension, subPlugins } = currentPlugin
    const { subProps } = props
    const { subRectOptions } = rectOptions
    const [activeIndex, setActiveIndex] = useState(0)
    return (
        <div
            className="desc-wrap"
        >
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="下层飞入图"
                detail="位于轮播下层，切换锚点时飞入"
            />
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="飞入图素材设置"
                        detail="对应锚点按组添加，每组上限1张，支持图片链接，图片可画热区"
                        style={{
                            height: 37,
                        }}
                        titleSize={14}
                        detailSize={12}
                    />
                    <Select
                        chooseList={props.flyImageList.map((v, i) => `第${i + 1}组`)}
                        onClick={(idx) => {
                            setActiveIndex(idx)
                        }}
                        currentIndex={activeIndex}
                    />
                    <InputWithImg
                        labelContent="图片链接"
                        defaultValue={props.flyImageList[activeIndex].imageUrl1}
                        onChange={(e) => {
                            const list = [...props.flyImageList]
                            list[activeIndex].imageUrl1 = e
                            updateCurrentPluginProps({
                                flyImageList: list,
                            })
                        }}
                    />
                    <HotArea
                        editContent={props.flyImageList[activeIndex].imageUrl1}
                        defaultValue={props.flyImageList[activeIndex].hotArea}
                        editContentSize={{
                            width: subRectOptions[0].width,
                            height: subRectOptions[0].height,
                        }}
                        contentFit="fill"
                        onSave={(data) => {
                            const list = [...props.flyImageList]
                            list[activeIndex].hotArea1 = data
                            updateCurrentPluginProps({
                                flyImageList: list,
                            })
                        }}
                    />
                    <Radio
                        title="飞入方向"
                        defaultValue={[
                            "左",
                            "右",
                            "上",
                            "下"
                        ].indexOf(props.flyImageList[activeIndex].flyDirection1)}
                        radioList={[
                            "左",
                            "右",
                            "上",
                            "下"
                        ]}
                        onChange={(v) => {
                            const list = [...props.flyImageList]
                            list[activeIndex].flyDirection1 = [
                                "左",
                                "右",
                                "上",
                                "下"
                            ][v]
                            updateCurrentPluginProps({
                                flyImageList: list,
                            })
                        }}
                    />
                    <Slider3x
                        title="飞入距离"
                        suffix="px"
                        defaultValue={props.flyImageList[activeIndex].flyDistance1}
                        min={extension.flyDistanceRange[0]}
                        max={extension.flyDistanceRange[1]}
                        onChange={(v) => {
                            const list = [...props.flyImageList]
                            list[activeIndex].flyDistance1 = v
                            updateCurrentPluginProps({
                                flyImageList: list,
                            })
                        }}
                    />
                    <Slider
                        title="飞入时间"
                        suffix="秒"
                        defaultValue={props.flyImageList[activeIndex].flyTime1}
                        min={extension.flyTimeRange[0]}
                        max={extension.flyTimeRange[1]}
                        onChange={(v) => {
                            const list = [...props.flyImageList]
                            list[activeIndex].flyTime1 = v
                            updateCurrentPluginProps({
                                flyImageList: list,
                            })
                        }}
                    />
                    <Space>
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<DeleteFilled />}
                            content="删除本组"
                            onClick={() => {
                                const list = [...props.flyImageList]
                                list.splice(activeIndex, 1)
                                if (list.length === 1) {
                                    deleteInsertPlugin(currentPlugin.insertId)
                                } else {
                                    setActiveIndex(0)
                                    updateCurrentPluginProps({
                                        flyImageList: list,
                                    })
                                }
                            }}
                        />
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<PlusOutlined />}
                            content="添加帧数"
                            onClick={() => {
                                const list = [...props.flyImageList]
                                list.push(Object.assign({}, extension.struct))
                                updateCurrentPluginProps({
                                    flyImageList: list
                                })
                            }}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            defaultValue={subRectOptions[activeIndex].width}
                            onChange={(e) => {
                                changeCurrentPluginRect({
                                    width: e,
                                }, activeIndex + 1)
                            }}
                        />
                        <InputNumber3x
                            labelContent="高"
                            defaultValue={subRectOptions[0].height}
                            onChange={(e) => {
                                changeCurrentPluginRect({
                                    height: e,
                                }, 1)
                            }}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            defaultValue={subRectOptions[0].top}
                            onChange={(e) => {
                                changeCurrentPluginRect({
                                    top: e,
                                }, 1)
                            }}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            defaultValue={subRectOptions[0].left}
                            onChange={(e) => {
                                changeCurrentPluginRect({
                                    left: e,
                                }, 1)
                            }}
                        />
                    </Space>
                </Space>
            </div>
        </div>
    )
}
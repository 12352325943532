import React, { useState, useEffect } from 'react'
import './index.less'
export default function InputWithLabel({
    labelContent = '',
    style = {},
    labelSize = {},
    onChange = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    onMouseOut = () => {},
    defaultValue = ''
}) {
    const [value, setValue] = useState(defaultValue)
    const wrapStyle = Object.assign({
        width: 320,
        height: 30
    }, style)
    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])
    return (
        <div
            className="input-with-label" style={wrapStyle}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseOut={onMouseOut}
        >
            <div className="before-label items-center justify-center">{labelContent}</div>
            <input
                className="input"
                placeholder="请输入"
                type="text"
                value={value}
                onChange={(e) => {
                    onChange(e.target.value)
                    setValue(e.target.value)
                }}
            />
        </div>
        
    )
}
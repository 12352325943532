import React from 'react'
import './index.less'

export default function TextArea({
    onChange=() => {},
    style = {},
    placeholder = '请输入文本',
    defaultValue = '',
}) {
    const [value, setValue] = React.useState(defaultValue)
    const wrapStyle = Object.assign({
        width: 320,
        height: 78,
    }, style)
    React.useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])
    return (
        <textarea
            className="text-area"
            style={wrapStyle}
            value={value}
            placeholder={placeholder}
            onChange={(e) => {
                setValue(e.target.value)
                onChange(e.target.value)
            }}
        />
    )
}
import { ORIGIN_DATA_TYPE } from '@/consts/plugin'
export const getConfigDataType = (data = {}, config) => {
    let result = ORIGIN_DATA_TYPE.NULL
    if (!config.origin_data) return result

    const targetData = data[config.origin_data.name]
    if (!targetData) return result

    const targetKey = config.origin_data.key
    Object.keys(targetData).forEach((key) => {
        if (Object.keys(targetData[key]).includes(targetKey)) {
            result = key
        }
    })
    if (result === 'properties') {
        result = ORIGIN_DATA_TYPE.PROPERTIES
    } else if (result === 'rect') {
        result = ORIGIN_DATA_TYPE.RECT
    }
    return result
}

export const parseDrawUIProps = (data, prop) => {
    const pathArr = prop.split('.')
    let result = data
    pathArr.forEach((point, index) => {
        if (point.indexOf('[') > -1 && point.indexOf(']') > point.indexOf('[')) {
            let [font, num] = point.split('[')
            num = Number(num.split(']')[0])
            if (result[font].value) {
                result = result[font].value[num]
            } else {
                result = result[font][num]
            }
        }
        if (result[point]) {
            if (result[point].value) {
                result = result[point].value
            } else {
                result = result[point]
            }
        }
    })
    return result
}
import React, { useState } from 'react'
import { Space } from 'antd'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import '@/plugins/desc.less'
import {
    Input,
    ColorPicker,
    Slider,
    ConfigTitle,
    TextArea,
} from '@/components'
import { InputNumber3x } from '@/HOC'

export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
    deleteInsertPlugin,
}) {
    const { props, rectOptions, extension } = currentPlugin
    const { width, height, left, top } = rectOptions
    const { text, speed, fontSize, fontWeight, color } = props
    return (
        <div
            className="desc-wrap"
        >
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="跑马灯"
                detail="文本向左循环滚动"
            />
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="滚动文本设置"
                        detail="文本字数无限制"
                        style={{
                            height: 'auto'
                        }}
                        titleSize={14}
                        detailSize={12}
                    />
                    <TextArea
                        defaultValue={text}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                text: e,
                            })
                        }}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    width: v,
                                })
                            }}
                            defaultValue={width}
                        />
                        <InputNumber3x
                            labelContent="高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    height: v,
                                })
                            }}
                            defaultValue={height}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    top: v,
                                })
                            }}
                            defaultValue={top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    left: v,
                                })
                            }}
                            defaultValue={left}
                        />
                    </Space>
                    <Slider
                        title="滚动速度"
                        suffix="s"
                        max={extension.speedDuration[1]}
                        min={extension.speedDuration[0]}
                        defaultValue={speed}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                speed: v
                            })
                        }}
                    />
                    <Slider
                        title="文字大小"
                        suffix="px"
                        max={extension.fontSizeDuration[1]}
                        min={extension.fontSizeDuration[0]}
                        defaultValue={fontSize}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                fontSize: v
                            })
                        }}
                    />
                    <Slider
                        title="文字粗细"
                        suffix=""
                        step={100}
                        max={extension.fontWeightDuration[1]}
                        min={extension.fontWeightDuration[0]}
                        defaultValue={fontWeight}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                fontWeight: v
                            })
                        }}
                    />
                    <ColorPicker
                        title="文字颜色"
                        defaultValue={color}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                color: v
                            })
                        }}
                    />
                </Space>
            </div>
        </div>
    )
}
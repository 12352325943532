import React from 'react'

export default function Template({
    rectOptions,
    props,
}) {
    const { width, height, top, left } = rectOptions
    const { imageUrl } = props // 试例，正式模版中会删掉
    return (
        <div
            style={{
                position: 'absolute',
                width: width, // 单位px
                height: height, // 单位px
                transform: `translate(${left}px, ${top}px)`
            }}
        >
            <img
                src={imageUrl}
                alt=""
                style={{
                    width,
                    height,
                }}
            />
        </div>
    )
}
// 子组件1
export function SubTemplate1({
    rectOptions,
    props,
}) {
    const { width, height, top, left } = rectOptions.subRectOptions[0]
    const { name } = props.subProps[0] // 试例，正式模版中会删掉
    return (
        <div
            style={{
                position: 'absolute',
                width: width, // 单位px
                height: height, // 单位px
                transform: `translate(${left}px, ${top}px)`,
                backgroundColor: 'pink',
                display: 'flex', // 要剧中必须携带的参数
                justifyContent: 'center', // 内部元素左右剧中
                alignItems: 'center' // 内部元素上下剧中
            }}
        >
            {name}
        </div>
    )
}

// 子组件2
export function SubTemplate2({
    rectOptions,
    props,
}) {
    const { width, height, top, left } = rectOptions.subRectOptions[1]
    const { name } = props.subProps[1] // 试例，正式模版中会删掉
    return (
        <div
            style={{
                position: 'absolute',
                width: width, // 单位px
                height: height, // 单位px
                backgroundColor: 'yellow',
                color: '#fff',
                transform: `translate(${left}px, ${top}px)`
            }}
        >
            {name}
        </div>
    )
}
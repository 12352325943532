import React from 'react'
import { ConfigTitle } from '@/components'

export default function DescTitle({
    type = 'main',
    title = '',
    detail = '',
}) {
    switch (type) {
        case 'main':
            return <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title={title}
                detail={detail}
            />
        case 'sub':
            return <ConfigTitle
                title={title}
                detail={detail}
                style={{
                    height: 21,
                }}
                titleSize={14}
                detailSize={0}
            />
        default:
            return null
    }
}
import Comp from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '倒计时背景图',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [],
    type: 'count-down-background',
    props: {
        startBgImgUrl: '//img20.360buyimg.com/zx/jfs/t1/154607/3/3658/185358/5f97ada0Eee76883d/c2d8b19eaaaf7f7f.png',
        endBgImgUrl: '//img30.360buyimg.com/zx/jfs/t1/134713/1/13753/185429/5f97ada0Ec8a836d8/a5fc7de7e09f0612.png',
        jumpUrl: '',
    },
    extension: {
    },
    rectOptions: {
        width: 375,
        height: 125.6,
        left: 0,
        top: 0,
        rotate: 0,
        subRectOptions: []
    },
    drawOptions: {
        resize: true,
        drag: false,
        rotate: false,
        keepRatio: false,
        renderDirections: ['s'],
        subOptions: []
    },
}

export default Plugin
import React from 'react'

export default function Img({
    imageUrl = '',
    imageWidth = 'auto',
    imageHeight = 'auto',
}) {
    return <img
        style={{
            width: imageWidth === 'auto' ? 'auto' : Number(imageWidth),
            height: imageHeight === 'auto' ? 'auto' : Number(imageHeight),
        }}
        src={imageUrl}
    />
}
import { useRefreshMediaRect } from '@/utils/hooks'

export default function Comp({
    rectOptions,
    props,
}) {
    const { width, height, left, top } = rectOptions
    const { imageUrl } = props
    useRefreshMediaRect(imageUrl, rectOptions)
    return (
        <div
            style={{
                width,
                height,
                position: 'absolute',
                transform: `translate(${left}px, ${top}px)`
            }}
        >
            <img src={imageUrl} alt="" style={{ width: '100%', height: '100%' }} />

        </div>
    )
}
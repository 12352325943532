import React, { Fragment, useState } from 'react'
import { Space } from 'antd'
import '@/plugins/desc.less'
import { ORIGIN_DATA_TYPE } from '@/consts/plugin'
import schema from './schema.json'
import { GroupDesc, SpaceDesc, DescTitle } from '../_component/descComponent'
import { getConfigDataType, parseDrawUIProps } from '../schemaUtils'
import {
    Input,
    Slider,
    Select,
    ConfigTitle,
    InputNumber,
} from '@/components'
import { InputNumber3x, InputWithImg, InputNumWithTitle, HotArea } from '@/HOC'

const { config_ui } = schema
export default function Desc(descProps) {
    const { props, rectOptions, extension } = descProps.currentPlugin
    return (
        <div
            className="desc-wrap"
        >
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title={schema.title}
                detail="模块实际显示宽高与背景图宽高一致，背景图可画热区"
            />
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={16}
                >
                    {
                        config_ui.map((config, index) => {
                            let renderData = {}
                            let renderMethod = () => {}
                            switch(getConfigDataType(schema.data, config)) {
                                case ORIGIN_DATA_TYPE.PROPERTIES:
                                    renderData = props
                                    renderMethod = descProps.updateCurrentPluginProps
                                    break
                                case ORIGIN_DATA_TYPE.RECT:
                                    renderData = rectOptions
                                    renderMethod = descProps.changeCurrentPluginRect
                                    break
                            }
                            return <Fragment key={index}>
                                {
                                    config.type === 'Group' && GroupDesc({
                                        props,
                                        rectOptions,
                                        config,
                                        schema,
                                        ...descProps
                                    })
                                }
                                {
                                    config.type === 'Space' && <SpaceDesc
                                        schema={schema}
                                        props={props}
                                        rectOptions={rectOptions}
                                        config={config}
                                        {...descProps}
                                    />
                                }
                                {
                                    config.type === 'Slider' && <Slider
                                        {...config.props}
                                        defaultValue={renderData[config.origin_data.key]}
                                        min={extension[`${config.origin_data.key}Range`][0]}
                                        max={extension[`${config.origin_data.key}Range`][1]}
                                        onChange={(v) => {
                                            renderMethod({
                                                [`${config.origin_data.key}`]: v
                                            })
                                        }}
                                    />
                                }
                                {
                                    config.type === 'InputNumber3x' && <InputNumber3x
                                        {...config.props}
                                        style={{
                                            color: '#696969',
                                        }}
                                        defaultValue={renderData[config.origin_data.key]}
                                        onChange={(val) => {
                                            renderMethod({
                                                [`${config.origin_data.key}`]: val
                                            })
                                        }}
                                    />
                                }
                                {
                                    config.type === 'InputNumWithTitle' && <InputNumWithTitle
                                        {...config.props}
                                        style={{
                                            color: '#696969',
                                        }}
                                        defaultValue={renderData[config.origin_data.key]}
                                        onChange={(val) => {
                                            renderMethod({
                                                [`${config.origin_data.key}`]: val
                                            })
                                        }}
                                    />
                                }
                                {
                                    config.type === 'InputWithImg' && <InputWithImg
                                        {...config.props}
                                        defaultValue={renderData[config.origin_data.key]}
                                        onChange={(val) => {
                                            renderMethod({
                                                [`${config.origin_data.key}`]: val
                                            })
                                        }}
                                    />
                                }
                                {
                                    config.type === 'HotArea' && <HotArea
                                        editContent={parseDrawUIProps(renderData, config.props.editContent.target)}
                                        editContentSize={{
                                            width: rectOptions.width,
                                            height: rectOptions.height,
                                        }}
                                        contentFit="fill"
                                        defaultValue={renderData[config.origin_data.key]}
                                        onSave={(data) => {
                                            renderMethod({
                                                [`${config.origin_data.key}`]: data
                                            })
                                        }}
                                    />
                                }
                                {
                                    config.type === 'ConfigTitle' && <DescTitle
                                        type="sub"
                                        {...config.props}
                                    />
                                }
                            </Fragment>
                        })
                    }
                </Space>
            </div>
        </div>
    )
}
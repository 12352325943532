import Comp from './comp'
import Preview from './preview'
import Desc from './desc'

const Plugin = {
    name: '背景图',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [],
    type: 'background',
    id: 'id10086', // 测试
    props: {
        imageUrl: '//img11.360buyimg.com/zx/jfs/t1/90881/20/42263/109402/64cb4fcaF5270517b/39e9a0bd5db74f8c.jpg',
        jumpUrl: '',
        subProps: []
    },
    extension: {},
    rectOptions: {
        width: 375,
        height: 480,
        left: 0,
        top: 0,
        rotate: 0,
        subRectOptions: []
    },
    drawOptions: {
        resize: true,
        drag: false,
        rotate: false,
        keepRatio: false,
        renderDirections: ['s'],
        subOptions: []
    },
}

export default Plugin
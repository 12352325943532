import superagent from 'superagent'
import { encrypt } from '@/utils/des'
import { host, tokenPrefix } from './host'

// 获取广告数据
const getAdvert = () => {
    return new Promise((resolve, reject) => {
        superagent.get(`${host}/v1/advertisement/detail`)
            .set('token', encrypt(`${tokenPrefix}.advertisement`))
            .send({})
            .then((res) => {
                if (res.body.err === 0) {
                    resolve(JSON.parse(res.body.data.data))
                }
            }, (err) => {
                reject(err)
            })
    })
    
}

export {
    getAdvert,
}
import React, { useState } from 'react'
import { Space } from 'antd'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import Comp from './comp'
import '@/plugins/desc.less'
import {
    Input,
    Slider,
    ConfigTitle,
    Select,
    BaseButton,
    Radio,
} from '@/components'
import { InputNumber3x, Slider3x, InputWithImg, HotArea } from '@/HOC'
import { InputNumWithTitle } from '../../HOC'

export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
    addSubPlugins,
    deleteInsertPlugin,
    deleteSubPlugins,
}) {
    const { props, rectOptions, extension, subPlugins } = currentPlugin
    const { imageUrl } = props
    const { width, height, left, top } = rectOptions
    const [activeIndex, setActiveIndex] = useState(0)
    return (
        <div
            className="desc-wrap"
        >
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="切换按钮"
                detail="点击切换另一张图片"
            />
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="按钮素材设置"
                        detail="支持图片链接"
                        style={{
                            height: 37,
                        }}
                        titleSize={14}
                        detailSize={12}
                    />
                    <InputWithImg
                        labelContent="图片链接"
                        defaultValue={imageUrl}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                imageUrl: e,
                            })
                        }}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            defaultValue={width}
                            onChange={(e) => {
                                changeCurrentPluginRect({
                                    width: e,
                                })
                            }}
                        />
                        <InputNumber3x
                            labelContent="高"
                            defaultValue={height}
                            onChange={(e) => {
                                changeCurrentPluginRect({
                                    height: e,
                                })
                            }}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            defaultValue={top}
                            onChange={(e) => {
                                changeCurrentPluginRect({
                                    top: e,
                                })
                            }}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            defaultValue={left}
                            onChange={(e) => {
                                changeCurrentPluginRect({
                                    left: e,
                                })
                            }}
                        />
                    </Space>
                </Space>
            </div>
        </div>
    )
}
import React from 'react'

export default function CountDownBG({ rectOptions, props }) {
    const { width, height, left, top } = rectOptions
    const { startBgImgUrl } = props
    return (
        <div
            style={{
                position: 'absolute',
                transform: `translate(${left}px, ${top}px)`,
                width,
                height,
            }}
        >
             <img
                style={{
                    width: '100%',
                    height: '100%',
                }}
                src={startBgImgUrl}
                alt=""
            />
        </div>
       
    )
}
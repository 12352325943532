import superagent from 'superagent'
import { encrypt } from '@/utils/des'
import { host, tokenPrefix } from './host'

const createModule = ({ type, name }) => {
    return new Promise((resolve, reject) => {
        superagent.post(`${host}/v1/module/create`)
            .set('token', encrypt(`${tokenPrefix}.module`))
            .send({
                class: type,
                name,
            })
            .then((res) => {
                if (res.body.err === 0) {
                    resolve(res.body.data)
                } else {
                    reject(res.body.message)
                }
            }, (err) => {
                reject(err)
            })
    })
}

const updateModule = (module_id, { name, type }) => {
    return new Promise((resolve, reject) => {
        superagent.post(`${host}/v1/module/update`)
            .set('token', encrypt(`${module_id}.module`))
            .send({
                module_id,
                name,
                class: type,
            })
            .then((res) => {
                if (res.body.err === 0) {
                }
            }, (err) => {
                reject(err)
            })
    }
    )
}

const deleteModule = (module_id) => {
    return new Promise((resolve, reject) => {
        superagent.put(`${host}/v1/module/${module_id}/delete`)
            .set('token', encrypt(`${module_id}.module`))
            .send({
                module_id,
            })
            .then((res) => {
                if (res.body.err === 0) {
                    resolve()
                }
            }, (err) => {
                reject(err)
            })
    }
    )
}

const getConfigModulesList = (matches = {}) => {
    return new Promise((resolve, reject) => {
        superagent.post(`${host}/v1/module/list/1/1000`)
            .set('token', encrypt(`${tokenPrefix}.module`))
            .send(matches)
            .then((res) => {
                if (res.body.err === 0) {
                    resolve(res.body.data)
                }
            }, (err) => {
                reject(err)
            })
    })
}

export {
    createModule,
    updateModule,
    deleteModule,
    getConfigModulesList,
}
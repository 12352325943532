import Comp, { ExpandArea } from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '展开收起',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [ExpandArea],
    type: 'expand-collapse-comp',
    props: {
        showImgUrl: '//img30.360buyimg.com/zx/jfs/t1/145549/32/12383/123582/5f99126eEf284eaeb/1b98d4d88fab1437.jpg',
        hideImgUrl: '//img13.360buyimg.com/zx/jfs/t1/152902/5/3772/123631/5f99126eEf6148b3d/e7ce4b44c7202d6e.jpg',
        subProps: [{
            banners: [{
                imageUrl: '//img11.360buyimg.com/zx/jfs/t1/151472/24/3601/355231/5f97adbfE93d01510/3e8c02cca86e70d6.jpg',
                hotArea: [],
            }],
            imageWidth: 375,
            imageHeight: 241,
        }]
    },
    extension: {
        struct: {
            imageUrl: '',
            hotArea: [],
        }
    },
    rectOptions: {
        width: 375,
        height: 142,
        left: 0,
        top: 0,
        rotate: 0,
        subRectOptions: [{
            height: 241,
        }]
    },
    drawOptions: {
        resize: true,
        drag: false,
        rotate: false,
        keepRatio: false,
        renderDirections: ['s'],
        subOptions: [{
            resize: true,
            drag: false,
            rotate: false,
            keepRatio: false,
            renderDirections: ['s'],
        }]
    },
}

export default Plugin
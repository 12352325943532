import React, { useState, useEffect } from 'react'
import { getDateDiffDHMS } from '@/utils'

export default function CountDownPreview({
    baseConfig,
    props,
    rectOptions,
    zIndex = 0,
    onTimeUpdate = () => {},
}) {
    const maxWidth = baseConfig.width
    const maxHeight = baseConfig.height
    const { subRectOptions } = rectOptions 
    const { subProps, startTime, endTime } = props
    const [timeDiff, setTimeDiff] = useState([0, 0, 0, 0])
    useEffect(() => {
        const timer = setInterval(() => {
            let value = [...timeDiff]
            if (startTime > Date.now()) {
                value = getDateDiffDHMS(startTime, Date.now())
            } else if (Date.now() >= startTime && Date.now() < endTime) {
                value = getDateDiffDHMS(Date.now(), endTime)
            }
            onTimeUpdate()
            setTimeDiff(value)
        }, 1000)
        return () => {
            clearInterval(timer)
        }
    }, [])
    return (
        <>
            {
                subProps.map((p, i) => {
                    const { show, fontSize, fontWeight, color } = p
                    const { left, top } = subRectOptions[i]
                    return (
                        <div
                            key={i}
                            style={{
                                position: 'absolute',
                                display: show === true ? 'block' : 'none',
                                fontSize,
                                fontWeight,
                                left,
                                top,
                                color,
                                zIndex,
                            }}
                        >{timeDiff[i]}</div>
                    )
                })
            }
        </>
    )
}
import React from 'react'

export default function Preview({
    props,
    rectOptions,
}) {
    const { height } = rectOptions
    const { imageUrl, jumpUrl } = props
    return (
        <img
            style={{
                width: '100%',
                height,
            }}
            src={imageUrl}
            onClick={() => {
                window.location.href = jumpUrl
            }}
        />
    )

}
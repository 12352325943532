import React, { useState } from 'react'
import './index.less'

export default function Comp ({ rectOptions, props }) {
    const { left, top } = rectOptions
    const { navList, between, direction, navWidth, navHeight } = props
    const [curIndex, setCurIndex] = useState(0)
    return (
        <div
            className="nav-comp-wrap"
            style={{
                transform: `translate(${left}px, ${top}px)`,
                display: 'flex',
                flexDirection: direction === 0 ? 'column' : 'row',
            }}
        >
            {
                navList.map((nav, index) => {
                    const { activeUrl, defaultUrl } = nav
                    return (
                        <img
                            key={index}
                            style={{
                                width: navWidth,
                                height: navHeight,
                                marginLeft: direction === 0 || index === 0 ? 0 : between,
                                marginTop: direction === 1 || index === 0 ? 0 : between,
                            }}
                            onClick={() => {
                                setCurIndex(index)
                            }}
                            src={index === curIndex ? activeUrl : defaultUrl} alt=""
                        />
                    )
                })
            }
        </div>
    )
}
import { useState, createContext } from 'react'

export const DrawPageStateContext = createContext({})

// 热区界面管理
const DrawPageState = ({ children }) => {
    const [showHotEdit, setShowHotEdit] = useState(false)
    const [showExportAndImport, setShowExportAndImport] = useState(false)
    const [currentModule, setCurrentModule] = useState(null)
    return (
        <DrawPageStateContext.Provider
            value={{
                currentModule,
                setCurrentModule,
                showHotEdit,
                setShowHotEdit,
                showExportAndImport,
                setShowExportAndImport,
            }}
        >
            {children}
        </DrawPageStateContext.Provider>
    )
}

export default DrawPageState
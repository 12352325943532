import React from 'react'

export default function ExpandHide({
    rectOptions,
    props,
}) {
    const { height } = rectOptions
    const { showImgUrl, hideImgUrl, expandHeight, jumpUrl, imageWidth, imageHeight, banners } = props
    return (
        <div
            style={{
                position: 'absolute',
                width: '100%',
                height: height,
            }}
        >
            <img
                style={{
                    width: '100%',
                    height: '100%',
                }}
                src={showImgUrl}
                alt=""
            />
        </div>
    )
}

export const ExpandArea = ({
    rectOptions,
    props,
}) => {
    const { height, subRectOptions } = rectOptions
    const expandHeight = subRectOptions[0].height
    const { banners, imageWidth, imageHeight } = props.subProps[0]
    return (
        <div style={{
            position: 'absolute',
            width: '100%',
            height: expandHeight,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'hidden',
            transform: `translate(0px, ${height}px)`,
        }}>
            {
                banners.map((banner, index) => {
                    return (
                        <img
                            style={{
                                flexShrink: 0,
                                width: imageWidth,
                                height: imageHeight,
                            }}
                            src={banner.imageUrl}
                            alt=""
                        />
                    )
                })
            }
        </div>
    )
}
import React from 'react'
import './index.less'

export default function AlertComp({ rectOptions, props }) {
    const { width, height, left, top } = rectOptions
    const { imageUrl } = props

    return (
        <div className="alert-comp-wrap comp-wrap"
            style={{
                width,
                height,
                transform: `translate(${left}px, ${top}px)`
            }}
        >
            {
                imageUrl.indexOf('.mp4') > -1 ? (
                    <video
                        src={imageUrl}
                        autoPlay
                        muted
                    />
                ) : (
                    <img src={imageUrl} alt="" />
                )
            }
            
        </div>
    )
}

export function CloseBtn({
    rectOptions, props
}) {
    const { width, height, left, top } = rectOptions.subRectOptions[0]
    const { closeImgUrl } = props.subProps[0]
    return (
        <div
            className="close-btn comp-wrap"
            style={{
                position: 'absolute',
                zIndex: 1,
                width,
                height,
                transform: `translate(${left}px, ${top}px)`
            }}
        >
            <img src={closeImgUrl} alt="" />
        </div>
    )
}

export function CountDown({
    rectOptions,
    props,
}) {
    const { animateTime, showTime } = props
    const { left, top } = rectOptions.subRectOptions[1]
    const { fontSize, fontWeight, color } = props.subProps[1]
    return (
        <div
            className="count-down comp-wrap"
            style={{
                fontSize,
                fontWeight,
                color,
                position: 'absolute',
                zIndex: 2,
                transform: `translate(${left}px, ${top}px)`
            }}
        >{ animateTime + showTime }</div>
    )
}

export function ScaleAlert({
    rectOptions,
    props
}) {
    const { imageUrl } = props
    const { width, height, top, left } = rectOptions.subRectOptions[2]
    return (
        <div className="alert-comp-wrap comp-wrap"
            style={{
                width,
                height,
                transform: `translate(${left}px, ${top}px)`
            }}
        >
            {
                imageUrl.indexOf('.mp4') > -1 ? (
                    <video
                        src={imageUrl}
                        autoPlay
                        muted
                    />
                ) : (
                    <img src={imageUrl} alt="" />
                )
            }
            
        </div>
    )
}
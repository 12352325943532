import React, { useState, useEffect } from 'react'

// 该组件预览的时候会和其他组件发生联动，也可能单独使用，所以要暴露接口出去
export default function Preview({
    baseConfig,
    rectOptions,
    props,
    currentIndex = 0,
    zIndex = 0,
    onClickNav = () => { },
}) {
    const { left, top } = rectOptions
    const { navList, navWidth, navHeight, between, direction } = props
    const [activeIndex, setActiveIndex] = useState(currentIndex)
    useEffect(() => {
        if (activeIndex !== currentIndex) {
            setActiveIndex(currentIndex)
        }
    }, [currentIndex])
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: direction === 0 ? 'column' : 'row',
                left,
                top,
                zIndex,
                position: 'absolute',
            }}
        >
            {
                navList.map((nav, index) => {
                    return (
                        <img
                            style={{
                                width: navWidth,
                                height: navHeight,
                                marginLeft: direction === 0 || index === 0 ? 0 : between,
                                marginTop: direction === 1 || index === 0 ? 0 : between,
                            }}
                            onClick={() => {
                                onClickNav(index)
                            }}
                            alt=""
                            src={activeIndex === index ? nav.activeUrl : nav.defaultUrl}
                        />
                    )
                })
            }
        </div>

    )
}
import React from 'react'

export default function Scroll({ rectOptions, props }) {
    const { left, top } = rectOptions
    const { scrollItemWidth, scrollItemHeight, scrollWrapWidth, between, scrollList } = props
    return (
        <div
            style={{
                position: 'absolute',
                width: scrollWrapWidth,
                height: scrollItemHeight,
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'flex-start',
                transform: `translate(${left}px, ${top}px)`,
            }}
        >
            {
                scrollList.map((item, index) => {
                    return (
                        <img
                            style={{
                                flexShrink: 0,
                                width: scrollItemWidth,
                                height: scrollItemHeight,
                                marginLeft: index === 0 ? 0 : between,
                            }}
                            src={item.imageUrl}
                            alt=""
                        />
                    )
                })
            }
        </div>
    )
}

export function ScrollDot({
    rectOptions,
    props,
}) {
    const { width, height, top, left } = rectOptions.subRectOptions[0]
    const {
        defaultWidth,
        activeWidth,
        between,
        radius,
        defaultColor,
        activeColor
    } = props.subProps[0]
    const step = width / props.scrollList.length
    return (
        <div
            style={{
                position: 'absolute',
                width,
                height,
                overflow: 'hidden',
                borderRadius: radius,
                backgroundColor: defaultColor,
                transform: `translate(${left}px, ${top}px)`,
            }}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: activeColor,
                    borderRadius: radius,
                    transform: `translateX(-${width - step * 1}px)`
                }}
            ></div>
        </div>
    )
}
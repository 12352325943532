import React, { useContext } from 'react'
import '../../desc.less'
import { Space } from 'antd'
import { Input, ConfigTitle, BaseButton} from '@/components'
import { InputNumber3x, InputWithImg } from '@/HOC'
import { BaseConfigContext } from '@/context'
export default function Desc({ currentPlugin, changeCurrentPluginRect, updateCurrentPluginProps }) {
    const { props, rectOptions } = currentPlugin
    const { maxSize } = useContext(BaseConfigContext)
    return (
        <div className="desc-wrap">
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="背景图片"
                detail="模块实际显示宽高与背景图宽高一致"
            />
            <div className="desc-board">
                <Space direction="vertical" size={16}>
                    <InputWithImg
                        labelContent="图片链接"
                        defaultValue={props.imageUrl}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                imageUrl: e,
                            })
                        }}
                    />
                    <Input
                        labelContent="跳转链接"
                        defaultValue={props.jumpUrl}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                jumpUrl: e,
                            })
                        }}
                    />
                    <Space size={10}>
                        <BaseButton
                            content="使用图片实际尺寸"
                            type="blue"
                            style={{
                                width: 155,
                                backgroundColor: '#477AFF'
                            }}
                            onClick={() => {
                                const _image = new Image()
                                _image.src = props.imageUrl
                                _image.onload = (e) => {
                                    const _imgWidth = e.target.width
                                    const _imgHeight = e.target.height
                                    const _ratio = _imgWidth / _imgHeight
                                    changeCurrentPluginRect({
                                        width: maxSize.width,
                                        height: Math.min(maxSize.height, maxSize.width / _ratio)
                                    })
                                }
                            }}
                        />
                        <InputNumber3x
                            labelContent="高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.height = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.height}
                        />
                    </Space>

                </Space>

            </div>
        </div>
    )
}
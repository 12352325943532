import React from 'react'
import './index.less'

const ConfigTitle = ({
    title,
    detail = '',
    titleSize = 16,
    detailSize = 13,
    style = {}
}) => {
    const wrapStyle = Object.assign({}, style)
    return (
        <div className="config-title-wrap" style={wrapStyle}>
            <div
                className="title"
                style={{
                    fontSize: titleSize,
                }}
            >
                { title }
            </div>
            {
                detail !== '' && (
                    <div className="detail" style={{
                        fontSize: detailSize,
                    }}>
                        {detail}
                    </div>
                )
            }
        </div>
    )
}

export default ConfigTitle